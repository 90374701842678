import { faSearch, faPaperclip, faDownload, faFilePdf, faFileImage, faFileExcel, faFileWord, faFile, faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React,{ useState } from "react";
import { useTranslation } from "react-i18next";
import mainAxios from "../../services/mainAxios";
import DocumentPreview from "../DocumentPreview";
import Table from "../Table";
import { purchaseAction } from "./columns/PurchaseAction";


const moment = require("moment");

const PurchasesHistoric = ({  purchaseHistorics , fromPurchaces}) => {
  const [loadingDocument,setLoadingDocument]=useState(false);
  const [filePreview,setFilePreview]=useState(null);
  const atachmentsRef = React.useRef()
  const { t } = useTranslation("translations");





  const columns = [
    {
      name:t("logs.action"),
      selector: "workflowAction",
      sortable: true,
      format: invoice => {
        if (invoice?.workflowAction?.name) {
          let purchaseActionProps = purchaseAction(invoice.workflowAction.name)
          return (
            <div className={`badge badge-${purchaseActionProps.color}`}>
              {purchaseActionProps.name}
            </div>)
        }
        return "";
      },

    },
    {
      name:t("purchases.purchaseStatus"),
      selector: "workflowStatus",
      format: history => history.workflowStatus?.name ?? "",
      sortable: true,
      width: "150px"

    },
    {
      name:t("purchases.cisStatus"),
      selector: "myPurchaseStatus",
      format: history => history.myPurchaseStatus?.name ?? "",
      sortable: true,
      width: "150px"

    },
    {
      name:t("table.purchaseHistoryDate"),
      selector: "recordDate",
      format: invoice => moment(invoice.recordDate).format("DD/MM/YYYY HH:mm"),
      sortable: true,
      width: "220px"
    },
    {
      name:t("logs.user"),
      selector: "recordUser",
      sortable: true,


    },
    {
      name: t("purchases.comment"),
      selector: "comment",
      cell: (purchaseHistoric) => {
        const commentContent = purchaseHistoric?.comment || "";
        const hasError = commentContent.startsWith("Error");
        if (purchaseHistoric?.comment?.length > 20) {
          return (
            <input
              type="text"
              value={commentContent}
              readOnly
              style={{
                border: 0,
                userSelect: "text",
                fontSize: "12px",
                width: "150px",
                backgroundColor: hasError ? "#f8d7da" : "white",
                color: hasError ? "#721c24" : "black", 
              }}
            />
          );
        }
        return commentContent;
      },
      sortable: true,
      width: "150px",
    }
,    
    {
      name:t("purchases.attachments"),
      selector: "purchaseHistoricAttachments",
      cell: (attachments) => {
        let purchaseHistoricAttachments = attachments?.purchaseHistoricAttachments;
        if (purchaseHistoricAttachments?.length > 0) {
          return <div className="d-flex align-items-center ">
            <FontAwesomeIcon icon={faPaperclip} />
            <div className="display-5 text-muted ml-1">{purchaseHistoricAttachments.length}</div>
          </div>
        }
      },
      width:150

    }
  ];

  const fetchDocument = (e, id, name, type, download = false) => {
    e.preventDefault()
    setLoadingDocument(true)
    mainAxios
      .post("endpoint/v2/getInternalAtachementByte", {
        value: id
      })
      .then((res) => {
        setLoadingDocument(false)
        if (res?.data?.status) {
          if (!download) {
            setFilePreview({
              name,
              type,
              base64: res.data.content[0]
            })
            setTimeout(() => {
              atachmentsRef.current.classList.toggle('open')
            }, 250)
          } else {
            const linkSource = `data:application/pdf;base64,${res.data.content[0]}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = name;
            downloadLink.click();
          }
        }
      })
      .catch((_) => {
        setLoadingDocument(false);
      })
  }


  const thumbs = (purchaseHistorics) => {

    const placeholder = (type) => {
      if (type == "pdf") {
        return faFilePdf;
      } else if (type == "jpg" || type == "jpeg" || type == "png") {
        return faFileImage;
      } else if (type == "xlsx") {
        return faFileExcel;
      } else if (type == "docx") {
        return faFileWord;
      } else {
        return faFile;
      }
    }

    if (purchaseHistorics?.purchaseHistoricAttachments) return purchaseHistorics.purchaseHistoricAttachments.map(({ id, name, type }, index) => {

      return (
        <div key={index} className="atachment d-flex flex-column justify-content-center align-items-center">
          <FontAwesomeIcon color="#5c656d" size="3x" icon={placeholder(type)} />
          <div className="atachment-name mx-1 text-center">
            {name}
          </div>
          <div className="d-flex mx-1 mt-3">
            <button
              className="attachment__actions--button bg-primary"
              onClick={(e) => { if (!loadingDocument) fetchDocument(e, id, name, type) }}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
            <button
              className="attachment__actions--button bg-info"
              onClick={(e) => { if (!loadingDocument) fetchDocument(e, id, name, type, true) }}>
              <FontAwesomeIcon icon={faDownload} />
            </button>
          </div>
        </div>
      )
    })
    return <></>
  }

  const closePreview = () => {
    setTimeout(() => {
      setFilePreview(null)
    }, 150);
    atachmentsRef.current.classList.toggle('open')
  }

  return (
    <div className={fromPurchaces ? "" : "border-0  mb-4 card-accordion p-0 p-md-5 "}>
      <Table
        columns={columns}
        data={purchaseHistorics}
        renderItem={(purchaseHistorics) => {
          return (
            <div>
              <div className="attachment__container d-flex flex-wrap">{thumbs(purchaseHistorics)}</div>
              <div className="pdf-container" ref={atachmentsRef}>
                {filePreview &&
                  <div className="pdf-viewer">
                    <div className="pdf-overlay" onClick={closePreview} />
                    <div className="pdf-content">
                      <DocumentPreview filePreview={{ name: filePreview.name, base64: filePreview.base64, extension: filePreview.type }} />
                    </div>
                  </div>
                }
              </div>
            </div>

          )
        }}
        defaultSortField="recordDate"
        defaultSortAsc={false}
        searchableKeys={["recordDate", "recordUser", "workflowStatus", "myPurchaseStatus.name","comment", ]}
      />
    </div>
  );
};

export default PurchasesHistoric;
