import React from "react";
import WTN from "./WTN";
import Loader from "../../Layout/Loader";
import { toast } from "react-toastify";
import { useAppContext } from "../../../AppContext";
import func, { isEmpty, validateInvoiceWtn } from "../../../services/functions";
import mainAxios from "../../../services/mainAxios";

import { useTranslation } from "react-i18next";
import invoiceStatuses from "../../../services/invoiceStatuses.json";
import AlertCertificate from "../../global/AlertCertificate";
import DemoWtn from "../demoWtn/DemoWtn";
import { faAngleDoubleRight, faFileInvoice, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreateWTNInvoice = (props) => {
  const { t, i18n } = useTranslation("translations");
  const {
    user: [user],
    selectedCompany: [selectedCompany],
    showVersion: showVersion,
    handleShowVersion,
    loadingVersion: loadingVersion,
    unitsOfMeasure,
    unitOfmeasureLoading,
  } = useAppContext();

  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );


  const branch =
  company.branches.find((branch) => branch.default) ||
  company.branches[0];

const businUnitCode =
  branch?.tcrTypes.filter((tcrType) => !!tcrType.tcrCode)[0]?.businUnitCode ||
  "";

const allTcrTypes = company.branches
  .map((branch) => branch.tcrTypes)
  .reduce((acc, next) => acc.concat(next), [])
  .filter(
    (tcrType) =>
      !!tcrType.tcrCode && tcrType.businUnitCode === businUnitCode
  );


const orderedTcrs = allTcrTypes.sort((a, b) => new Date(b.recordDate) - new Date(a.recordDate));

const tcrCode = orderedTcrs?.[0]?.tcrCode || "";



  const [errors, setErrors] = React.useState({});



  const seller = {
    sellerIDType: "NUIS",
    name: company.name,
    tin: company.nuis,
    address: branch.sellerAddress,
    country: branch.sellerCountry,
    town: branch.sellerTown,
  };

  const [pageState, setPageState] = React.useState({
    loading: false,
  });

  let unitsOfMeasureDefault = unitsOfMeasure?.find(unitsOfMeasure => unitsOfMeasure?.code === "C62") ?? null;

 

  const [state, setState] = React.useState({
    parties: {
      seller,
      transporter: {
        name: "",
        idType: "NUIS",
        address: "",
        town: "Tiranë",
        country: "ALB",
        idNum: "",
      },
    },
    address: [
      {
        address: "",
        city: "Tiranë",
        point: "WAREHOUSE",
        type: "start",
        date: new Date(),
      },
      {
        address: "",
        city: "Tiranë",
        point: "OTHER",
        type: "destination",
        date: "",
      },
    ],
    type: "WTN",
    transaction: "TRANSFER",
    issueDate: new Date(),
    operatorCode:
      user?.userCompanyOperator?.find(
        (companyOperator) => companyOperator.company === company.id
      )?.operator || null,
    businessUnitCode: businUnitCode,
    tcrCode,
    goodsFammable: false,
    escortRequired: false,
    packType: "Boxes",
    packNum: "",
    valueOfGoods: "",
    internalId: "",
    subseq:"",
    vehicle: {
      vehicleOwnership: "OWNER",
      vehiclePlate: "",
    },
    items: [
      { 
        id: '1',
        code: "",
        name: "",
        unitOfMeasureId: unitsOfMeasureDefault ?? null,
        quantity: "",
    },
    ],
  });

  const handleSave = () => {
    const validateInvoiceFields = validateInvoiceWtn({ setErrors, toast, state: state,t })
    const { vehicle: { vehiclePlate }, } = state;
    if (!state.items.length)  {
      toast.error("Items should be completed!", {
        containerId: "all_toast",
      });
      setPageState((setPageState) => ({
        ...setPageState,
        saving: false,
      }));
    } 
    if(!new RegExp(/^([a-zA-Z0-9-]+)$/).test(vehiclePlate)) {
      toast.error(t(["toast.incorrectPlate"]), { containerId: "all_toast" });
    }else{
      setPageState((setPageState) => ({
        ...setPageState,
        loading: false,
        saving: false,
      }));
    }
    if(validateInvoiceFields) return  
          
    if (state.parties.transporter.idType) state.parties.transporter.idType = state.parties.transporter.idType.trim();
    if (state.parties.transporter.name) state.parties.transporter.name = state.parties.transporter.name.trim();
    if (state.parties.seller.name) state.parties.seller.name = state.parties.seller.name.trim();

      mainAxios.post("apiEndpoint/saveOrUpdate", {
          object: "CustomWTN",
          content: {
            status: invoiceStatuses[0],
            ...state,
            itemsNum: state.items.length,
            items: state.items.map((item) => {
              const { id, ...rest } = item;
              return rest;
            }),
          },
          nuis: company.nuis.trim(),
        })
        .then((res) => {
          if (res?.data?.status === true) {
            toast.success(t(["toast.draftSaved"]), {
              containerId: "all_toast",
            });
            props.history.push("/edit-wtn/" + res.data.content[0].id);
          }
          setPageState((setPageState) => ({
            ...setPageState,
            saving: false,
          }));
        })
        .catch(() => {
          setPageState((setPageState) => ({
            ...setPageState,
            saving: false,
          }));
        });

  };

  return !pageState.loading || !unitOfmeasureLoading ? (
    <div className="row  m-2 rounded">
      <div className={`${false ? "col-xl-10" : "col-xl-12"} col-12 mx-auto wizard-form`}>
        {
          false
            ?
            <>  
             <div className="mt-4 mb-4 d-flex align-items-center justify-content-between">
                <div className="d-flex gap-4 align-items-center">
                  <h3 className="mb-0">{t("navbar.createWTN")}</h3>
                  <AlertCertificate />
                {/* {showBanner && (
                 <div className="mb-2 mt-1">
                      <button
                        className="btn-sm btn-success w-100"
                        onClick={handleShowVersion}
                        disabled={loadingVersion}>
                           <FontAwesomeIcon className="mr-2" icon={faFileInvoice} />
                           <span style={{ fontSize: "13px" }}>
                           {t("invoice.alertInvoiceNew")}
                           {loadingVersion ? (
                               <FontAwesomeIcon className="ml-2 fa-spin" icon={faSpinner} />
                           ) : (
                               <FontAwesomeIcon className="ml-2" icon={faAngleDoubleRight} style={{ verticalAlign: 'middle' }} />
                           )}
                           </span>
                        </button>
                 </div>
                 )} */}
                </div>
                  <div>
                  </div>
                  <div>
                  <button
                    className="btn btn-success"
                    disabled={pageState?.saving}
                    style={{ minWidth: "80px" }}
                    onClick={handleSave}
                  >
                    {pageState.saving ? (
                      <Loader size="xs" color="white" />
                    ) : (
                      t(["common.save"])
                    )}
                  </button>
                  </div>

                </div>

              <WTN wtnState={state} setErrors={setErrors} errors={errors} handleSave={handleSave} pageState={pageState} setWtnState={setState} canEdit={true} />
            </>
            :
            <>
              <DemoWtn wtnState={state} setErrors={setErrors} errors={errors} handleSave={handleSave} pageState={pageState} setWtnState={setState} canEdit={true} unitsOfMeasure={unitsOfMeasure} />
            </>
        }
      </div>
    </div>
  ) : (
    <Loader />
  );

};

export default CreateWTNInvoice;
