import React, { memo } from "react";

const CellInput = ({ value, minWidth = 80, width = 180 }) => {
  const calculatedWidth = Math.min(
    Math.max(value.length * 10, minWidth),
    width
  );

  if (value?.length > 0) {
    return (
      <input
        type="text"
        onFocus={(event) => event.target.select()}
        value={value ?? ""}
        style={{
          border: 0,
          userSelect: "text",
          fontSize: "12px",
          width: calculatedWidth,
          backgroundColor: "transparent",
        }}
        onChange={() => {}}
      />
    );
  }
  return <></>;
};


export const CellTextarea = ({ value, minWidth = 80, width = 180 }) => {
  const calculatedWidth = Math.min(
    Math.max(value.length * 10, minWidth),
    width
  );

  if (value?.length > 0) {
    return (
      <textarea
        onFocus={(event) => event.target.select()}
        value={value ?? ""}
        rows={2}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "5px",
          fontSize: "12px",
          width: calculatedWidth,
          resize: "vertical", 
          outline: "none", 
          overflow: "hidden",
          // boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
        }}
        onChange={() => {}}
      />
    );
  }
  return <></>;
};
export default memo(CellInput);
