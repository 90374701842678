import React, { useState } from 'react'
import { useAppContext } from '../../../AppContext';
import { globalHandleChange } from '../../../services/functions';
import { Checkbox } from 'primereact/checkbox';
import Loader from '../../Layout/Loader';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import DatePicker from "react-datepicker";
import { Column } from 'primereact/column';
import mainAxios from '../../../services/mainAxios';
import { toast } from 'react-toastify';
import { useMemo } from 'react';


const useEditInCisTcr = ({ tcrData = [],company,refreshGrid } = {}) => {
    const defaultState={
        selectedRows: [],
        actionLoading: false,
        validTo: company.expirationDate?new Date():"",
        openEditTcrInCis:false
    }
    const [state, setState] = useState(defaultState)
    const { t,i18n } = useTranslation("translations");
    const {
        user: [user],
        selectedCompany: [selectedCompany],
    } = useAppContext();

    const handleChange = (key, value) => globalHandleChange({ key, value, setState })

    const nuis = company.nuis
    const selectedTcrs=useMemo(()=>state.selectedRows.map(tcr=>tcr.tcrCode).join(","),[state.selectedRows])

    const editInCisTcrAction=()=>{
        const businUnitCode=[...new Set(state.selectedRows.map(tcr=>tcr.businUnitCode))]
        const tcrIds=state.selectedRows.map(tcr=>tcr.tcrIntID)


        handleChange("actionLoading",true)
        mainAxios.post(`/apiEndpoint/${company.nuis}/modifyTcrsFromCis`,{
           nuis,
           businUnitCode,
           tcrIds,
           validTo: state.validTo,
        }).then(res=>{
            handleChange("actionLoading",false)

            if(res.data.status){
                setState({...defaultState})
                toast.success("TCR u modifikua me sukses", {
                    containerId: "all_toast",
                });
            }
            handleChange("openEditTcrInCis",false)
            refreshGrid()
        })
        .catch(err=>{
            handleChange("actionLoading",false)
        })
    }


    const editTcrInCisFilter = () => {
        const hasExistCis = tcrData.some(tcr => tcr.existCis && tcr.status==100);
    
        if (!hasExistCis) {
            return <></>;
        }
    
        return (
            <Checkbox
                onChange={(e) => {
                    if (e.checked) {
                        const _selectedRows = tcrData.filter(tcr => tcr.existCis && tcr.status==100);
                        handleChange("selectedRows", _selectedRows);
                    } else {
                        handleChange("selectedRows", []);
                    }
                }}
                checked={state?.selectedRows?.length === tcrData.filter(tcr => (tcr.existCis && tcr.status==100)).length}
            />
        );
    };
    
    const editTcrInCisBody = (tcr) => {
        if (tcr?.existCis && tcr.status==100) {
            return (
                <Checkbox
                    onChange={(e) => {
                        setState((state) => {
                            const _selectedRows = [...state.selectedRows];
                            const checkedValueIndex = _selectedRows.findIndex(sRow => sRow.id === tcr?.id);
    
                            if (checkedValueIndex >= 0) {
                                _selectedRows.splice(checkedValueIndex, 1);
                            } else {
                                _selectedRows.push(tcr);
                            }
    
                            return { ...state, selectedRows: _selectedRows };
                        });
                    }}
                    checked={!!state.selectedRows.find(sRow => sRow.id === tcr?.id)}
                />
            );
        }
        return <></>;
    };
    

    const editTcrInCisActions = (
        state?.selectedRows?.length > 0 &&
        <div className='mr-4'>
            <div className="d-flex align-items-center">
                <button className="btn btn-sm btn-outline-danger mr-3" onClick={async () => {
                        if (state.actionLoading) return;
                        setState((state)=>({...state,openEditTcrInCis:true}))
                        handleChange("openEditTcrInCis", true)
                    }}>
                    {state.actionLoading ? <Loader size="xs" /> : t(["company.modifyInCis"])}
                </button>
                <button className="btn btn-sm btn-outline-secondary mr-3"
                    disabled={state.actionLoading}
                    onClick={async () => {
                        if (state.actionLoading) return;
                        handleChange("selectedRows", [])
                    }}>
                    {state.actionLoading ? <Loader size="xs" /> : t("common.clearSelection")}
                </button>
            </div>
            <Dialog
                header={t("company.modifiedTcrs",{length:state?.selectedRows?.length ?? "0"})}
                visible={state.openEditTcrInCis}
                onHide={() => handleChange("openEditTcrInCis", false)}
                style={{ width: "550px", height: "580px" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className="d-flex flex-column h-full">    
                    <div className="card border-0 flex-grow">
                        <div className="card-body">
                        <div className='d-flex flex-column mb-4'>
                            <div>
                                {t("company.youHaveSelected")}
                            </div>
                            <div style={{fontWeight:700}}>
                                {selectedTcrs}
                            </div>
                        </div>


                        <div className="form-group">
                            <div className="form-group">
                                <label>{t('subscription.validTo')}
                                </label>
                                <DatePicker
                                    minDate={new Date()}
                                    selected={state?.validTo ?? ""}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={date => handleChange("validTo", new Date(date.setHours(2, 0, 0, 0)))}
                                    customInput={<input className="form-control" required />}
                                />
                            </div>
                        </div>
                            <div className="form-group">
                                <label >
                                {t(["invoice.Nuis"])}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={nuis}
                                    disabled={true}
                                    onChange={() => { }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-auto">
                    <div className="btn btn-success mr-2" onClick={editInCisTcrAction}>
                            {state.actionLoading ? <Loader size="xs" /> : t(["company.yes"])}
                        </div>
                        <div className="btn btn-secondary mr-2" onClick={async () => { handleChange("openEditTcrInCis", false) }}>
                            {t("common.cancel")}
                        </div>
                    </div>
                </div>


            </Dialog>
        </div>
    )

    const selectedRowsColumn = (
        <Column
            filter={true}
            showFilterMenu={false}
            showClearButton={false}
            filterElement={editTcrInCisFilter}
            body={editTcrInCisBody}
            headerStyle={{ width: '3rem' }} frozen />
    )




    return { editTcrInCisActions, selectedRowsColumn,registerTcrState:state }
}

export default useEditInCisTcr