import React, {useMemo, useState} from "react"
import Table from "../Table"

import mainAxios from '../../services/mainAxios'

import "react-datepicker/dist/react-datepicker.css"
import { useAppContext } from "../../AppContext"
import { toast } from "react-toastify"
import Loader from "../Layout/Loader"
import {useTranslation, withTranslation} from "react-i18next";
import ReactJson from 'react-json-view'
import Select from "react-select"
import selectStyle, { customStyles } from "../../design/selectStyle"


const SearchInvoices = () => {
    
    const {t,i18n} = useTranslation('translations');
    const {selectedCompany: [selectedCompany],user:[user]} = useAppContext()

    const [params, setParams] = useState({
        fic: "",
        transactionType: "sales",
    })
    const [loading, setLoading] = useState(false)

    const [invoices, setInvoices] = useState([])

    const resetState=()=>{
        setParams(params=>({...params,fic:""}))
        setInvoices([])
    }

    const transactionTypes=useMemo(()=>[
        { label:"Sales",value:"sales" },
        { label:"Purchases",value:"purchase" }
    ],[])

    const getFilteredResults = () => {
        setLoading(true)

        console.log({company,user,params});
        const company=parseInt(selectedCompany)
        mainAxios.post('apiEndpoint/test/from-cis',{
            params:{
            ...params
        },
        company,
        nuis: user.companiesRights.find(
            (c) => c.id === company
        )?.nuis

    }).then(res => {
        console.log({res});

            if(res?.status === 200) {
                setInvoices(res.data)
            }
            else {
                setInvoices([])
            }
            setLoading(false)
        })
        .catch(err=>{
            setLoading(false)
        })
    }

    
    return (
        <>
            <h3 className="my-2">{t('navbar.invoices')}</h3>
            <div className="card border-0 shadow">
                <div className="card-body">
                    <div className="d-flex align-items-end gap-2 mb-4">
                        <div>
                            <label className="w-100">FIC:</label>
                            <input 
                                className="form-control"
                                value={params.fic}
                                style={{width:350,height:38}}
                                onChange={(e)=>{
                                    const value=e.target.value
                                    console.log({value})
                                    setParams((params)=>({...params,fic:value}))}}/>
                        </div>
                        <div style={{width:150}}>
                            <label className="w-100">Type:</label>
                            <Select
                                styles={selectStyle}
                                options={transactionTypes}
                                value={ transactionTypes.find(transactionObj=>transactionObj.value === params.transactionType ) }
                                onChange={opt=>setParams(({...params,transactionType:opt.value}))}
                            />
                        </div>
                            <button 
                                className="btn btn-md btn-light"
                                disabled={loading}
                                style={{minWidth: "80px"}}
                                onClick={() => resetState()}>
                                {t("common.clear")}
                            </button>
                            <button 
                                className="btn btn-md btn-primary"
                                disabled={loading}
                                style={{minWidth: "80px"}}
                                onClick={() => getFilteredResults()}>
                                {loading ? <Loader size="xs"/> : t(['navbar.search'])}
                            </button>
                    </div>

                    <div>
                        <ReactJson src={invoices} theme="ashes"  />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchInvoices
