export const tinTypes = [
    { label: "NUIS", value: "1" },
    { label: "ID", value: "2" },
    { label: "PASS", value: "3" },
    { label: "VAT", value: "4" },
    { label: "TAX", value: "5" },
    { label: "SOC", value: "6" },
];

export const defaultTableRows ={
  totalRows: 0,
  rowsPerPage: 10,
  currentPage: 1,
}

export const myinvoiceTypes = [
  { value:"badDebt",label:"Bad Debt" },
  { value:"reverse",label:"Reverse" },
  { value:"selfInvoice",label:"Self Invoice"},
  { value:"summary",label:"Summary"}
]

export const paymentOptions=[
    {
      value:"NONCASH",
      label:"Non Cash"
    },
    {
      value:"CASH",
      label:"Cash"
    },
  ]

export const subscriptionStatus={
  active:"1",
  upcoming:"2",
  expired:"4",
  canceled:"3",
  suspended:"5"

}


export const partiesDialogTypes={
  seller:"SELLER",
  buyer:"BUYER"
}


export const getTcrList=(company)=>{
  let tcrList = [];

    (company?.branches ?? [])
    .reduce(
        (acc, branch) =>
            branch?.tcrTypes?.length > 0 ? acc.concat(branch.tcrTypes) : acc,
        []
    )
    .forEach((tcr) => {
        if (tcr?.allowPurchase) {
            let sellerName =
                company?.branches?.find(
                    (branch) => branch?.businUnitCode == tcr?.businUnitCode
                )?.sellerName ?? "";
            tcrList.push({
                label: tcr.tcrCode + " - " + sellerName,
                value: tcr.tcrCode,
            });
        }
    });
  return tcrList.length > 0
    ? [{ label: "Select", value: null }].concat(tcrList)
    : tcrList;
}