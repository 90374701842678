import { faCheck, faClose, faEdit, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import TooltipOverlay from '../../global/TooltipOverlay';
import PrimeTable, { verifiedBodyTemplate } from '../../PrimeTable/PrimeTable';
import { useMemo } from 'react';
import { Preloader, ThreeDots } from 'react-preloader-icon';
import mainAxios from '../../../services/mainAxios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Checkbox } from 'primereact/checkbox';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Dialog } from "primereact/dialog";
import RegisterTCR from './RegisterEditTcr';
import Loader from '../../Layout/Loader';
import { useAppContext, usePersistedState } from '../../../AppContext';
import { classNames } from "primereact/utils";
import RegisterEditTcr from './RegisterEditTcr';
import moment from 'moment';
import ExportCsvPrime from '../../global/ExportCsvPrime';
import CellInput from '../../global/CellInput';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import CisMessage from '../../global/CisMessage';
import useEditInCisTcr from './useEditInCisTcr';



const Tcrs = ({ company, setCompany }) => {

    const [tcrLoading, setTcrLoading] = useState({ id: null, loading: false });
    const [updateState, setUpdateState] = useState(null)
    const [tcrData, setTcrData] = useState()
    const [tcrForCsv, setTcrForCsv] = useState([]);
    const [editCurrentRow, setEditCurrentRow] = useState()
    const { t } = useTranslation("translations");
    const {
        user: [user],
        selectedCompany: [selectedCompany],
        getCompany
    } = useAppContext();

    const allTcrs = useMemo(() => {
        if (!company) return []
        return company.branches.reduce((acc, next) => acc.concat(next.tcrTypes), [])
    }, [company])
    const refreshGrid=()=>getTcrsFromCis({updatedFilters:filters,rowsPerPage:tableRows.rowsPerPage,currentPage:tableRows.currentPage})
    const {editTcrInCisActions,selectedRowsColumn} = useEditInCisTcr({tcrData,company,refreshGrid})
    const defaultFilters={params:{}}
    const [filters, setFilters] = React.useState(defaultFilters);
    const [loadingFromCis, setLoadingFromCis] = useState(false)
    const [tcrDialog, setTcrDialog] = useState({ visible: false, tcr: null })
    const [tableRows, setTableRows] = useState({
        totalRows: 0,
        rowsPerPage: 10,
        currentPage: 1,
    });
    const isSysAdmin=[1].includes(user?.role?.id)
    const handleChange = ({ key, value, tcr }) => {
        setUpdateState((updateState) => ({
            ...updateState,
            [tcr.id]: {
                ...(updateState?.[tcr?.id] ?? tcr),
                [key]: value
            }
        }))
    };


    React.useEffect(() => {
        getTCR()
    }, []);

    const getTCR = ({ updatedFilters, rowsPerPage = 10, currentPage = 1, exportCsv = false, sortCriteria } = {}) => {
        return mainAxios.post("/apiEndpoint/search", {
            object: "GetTcrView",
            params: updatedFilters?.params,
            pagination: exportCsv ? null : {
                pageSize: rowsPerPage,
                pageNumber: currentPage,
            },
            nuis: company.nuis,
            company: company.id,
            sortCriteria
        }).then((res) => {
            if (res?.data?.status === true) {
                if (exportCsv) {
                    setTcrForCsv(res.data.content)
                    return res.data.content
                } else {
                    setTcrData(res.data.content);
                    setTableRows((tableRows) => ({
                        ...tableRows,
                        totalRows: res?.data?.totalSize,
                    }));
                }
            }
            setTcrData(res.data.content ?? [])
            setTableRows((tableRows) => ({
                ...tableRows,
                totalRows: res?.data?.totalSize,
            }));
        }).catch(err => {
            console.log(err);
        })
    }

    const profiscActiveFilter = (filterOptions) => {
        let value = null
        if (filters?.params?.status === "100") {
            value = true
        } else if (filters?.params?.status === "-100") {
            value = false
        }
        return (
            <div className="d-flex justify-content-center">
                <TriStateCheckbox
                    value={value}
                    onChange={(e) => {
                        let value = null
                        if (e.value == true) value = "100"
                        else if (e.value == false) value = "-100"
                        let { currentPage, rowsPerPage, sortCriteria } = tableRows
                        filterOptions.filterCallback(e.value);

                        setFilters((filters) => {
                            const content = {
                                ...filters,
                                params: {
                                    ...filters.params,
                                    status: value
                                },
                            }
                            getTCR({ updatedFilters: content, rowsPerPage, currentPage, sortCriteria })
                            return content
                        })
                    }}
                />
            </div>
        )
    }

    const tcrColumns = [
        ({
            ...[1].includes(user?.role?.id) && ({
                header: t(["home.actions"]),
                field: "actions",
                style: { minWidth: 120, maxWidth: 200 },
                body: (tcr) => (
                    <Link to="#" className="nav-link p-0" onClick={(e) => {
                        e.preventDefault();
                        setTcrDialog({ visible: true, tcr })
                    }}>
                        <FontAwesomeIcon icon={faEdit} className="mr-1" />
                        {t("company.edit")}
                    </Link>
                ),
                default:true

            })
        }),
        {
            header: t("purchases.allowPurchases"),
            field: "allowPurchase",
            style: { minWidth: 160, maxWidth: 200 },
            sortable: true,
            filter: true,
            filterType: "TRISTATECHECKBOX",
            dataType: "boolean",
            default:true
        },
        {
            header: t("purchases.allowInvoices"),
            field: "allowInvoice",
            style: { minWidth: 150, maxWidth: 200 },
            sortable: true,
            filter: true,
            filterType: "TRISTATECHECKBOX",
            dataType: "boolean",
            default:true

        },
        // {
        //     header: t("invoice.operatorCode"),
        //     field: "operatorCode",
        //     style: { minWidth: 150, maxWidth: 200 },
        //     // filter: true
        // },
        {
            header: t("stat.tcrCode"),
            field: "tcrCode",
            style: { minWidth: 150, maxWidth: 200 },
            sortable: true,
            filterConfig: {
                like: true
            },
            filter: true,
            default:true
        },
        {
            header: t("purchases.warnings"),
            field: "expiresThisMonth",
            style: { minWidth: 200, maxWidth: 200 },
            body: (user) => {
                const currentDate = new Date()
                return (
                    <>
                        {moment(currentDate).isBefore(user?.validTo) &&
                            moment(currentDate).isSame(user?.validTo, 'month') ? (
                            <div style={{ backgroundColor: '#ffc107', color: '#664d03', padding: '2px 7px', borderRadius: '5px', fontSize: "13px", fontWeight: "500", display: 'inline-flex', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                {t(["company.expiresWarning"])}
                            </div>
                        ) : null
                        }
                        {moment(currentDate).isAfter(user?.validTo) ?
                            <div style={{ backgroundColor: '#e03131', color: '#ffffff', padding: '2px 7px', borderRadius: '5px', fontSize: "13px", fontWeight: "500", display: 'inline-flex', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                {t("company.hasExpired")}
                            </div> : null
                        }
                    </>

                )
            },
            default:true
        },
        {
            header: t("nslf.softCode"),
            field: "softwareCode",
            style: { minWidth: 150, maxWidth: 200 },
            sortable: true,
            filter: true,
            default:true
        },
        {
            header: t("purchases.bussinesCode"),
            field: "businUnitCode",
            style: { minWidth: 160, maxWidth: 200 },
            sortable: true,
            filter: true
        },
        {
            header: t("purchases.technical"),
            field: "isTechnical",
            filterConfig: {
                field: "isTechnical"
            },
            filter: true,
            style: { minWidth: 150, maxWidth: 200 },
            sortable: true,
            filterType: "TRISTATECHECKBOX",
            dataType: "boolean",
            default:true
        },
        {
            header: t("subscription.validFrom"),
            field: "validFrom",
            style: { minWidth: 160, maxWidth: 200 },
            sortable: true,
            formattedExportValue: true,
            body: (tcr) =>
                tcr?.validFrom
                    ? moment(tcr?.validFrom).format("DD/MM/YYYY HH:mm")
                    : "",
            default:true
                    
        },
        {
            header: t("subscription.validTo"),
            field: "validTo",
            style: { minWidth: 150, maxWidth: 200 },
            body: (tcr) =>
                tcr?.validTo
                    ? moment(tcr?.validTo).format("DD/MM/YYYY HH:mm")
                    : "",
            formattedExportValue: true,
            default:true


        },
        {
            header: t("invoice.type"),
            field: "type",
            sortable: true,
            default:true
            // filter:true,
            // filterType: "DROPDOWN",
            // filterField:"type",
            // filterConfig: {
            //     options: typeOptions,
            //     optionValue: "value",
            //     optionLabel: "label",
            // },
        },
        {
            header: t("selfServices.existInCis"),
            field: "existCis",
            dataType: "boolean",
            style: { minWidth: 170, maxWidth: 200 },
            sortable: true,
            filter: true,
            filterType: "TRISTATECHECKBOX",
            dataType: "boolean",
            default:true
            // filter:true,
            // filterType: "DROPDOWN",
            // filterField:"type",
            // filterConfig: {
            //     options: typeOptions,
            //     optionValue: "value",
            //     optionLabel: "label",
            // },
        },
        {
            header: t("selfServices.lastUsed"),
            field: "lastUsed",
            style: { minWidth: 190, maxWidth: 200 },
            sortable: true,
            formattedExportValue: true,
            body: (tcr) => tcr?.lastUsed
                ? moment(tcr?.lastUsed).format("DD/MM/YYYY HH:mm")
                : "",
            default:true
            
        },
        {
            header: t("selfServices.lastModified"),
            field: "modificationDate",
            style: { minWidth: 200, maxWidth: 200 },
            sortable: true,
            formattedExportValue: true,
            body: (tcr) => tcr?.modificationDate
                ? moment(tcr?.modificationDate).format("DD/MM/YYYY HH:mm")
                : "",
            default:true
            
        },
        {
            header: t("selfServices.lastModifiedUser"),
            field: "modificationUser",
            filter: true,
            style: { minWidth: 200, maxWidth: 220 },
            sortable: true,
        },
        {
            header: t("selfServices.profiscActive"),
            field: "profiscActive",
            body: (row) => verifiedBodyTemplate(row.status === 100),
            format: (row) => { return row.status != null ? (row.status === 100 ? "TRUE" : "FALSE") : "" },
            formattedExportValue: true,
            style: { minWidth: 170, maxWidth: 220 },
            sortable: true,
            filter: true,
            filterElement: (filterOptions) => profiscActiveFilter(filterOptions),
            default:true

        },
        {
            header: t("selfServices.lastInvoiceNumber"),
            field: "lastInvoiceNumber",
            filter: true,
            style: { minWidth: 190, maxWidth: 220 },
            body: (tcr) => <CellInput value={tcr?.lastInvoiceNumber ?? ""} width={200} onFocus={(event) => event.target.select()} />,
            sortable: true,
            default:true
        },
        {
            header: t("company.nextNumber"),
            sortable: true,
            filter: true,
            field: "nextNumber",
            style: { minWidth: "200px", maxWidth: "200px" },
            default:true

        },
        {
            header: t("company.TCRIntId"),
            sortable: true,
            filter: true,
            field: "tcrIntID",
            style: { minWidth: "200px", maxWidth: "200px" },
            default:true
        },
        {
            header: t("company.cisUpdate"),
            sortable: true,
            field: "cisLastUpdate",
            body: (tcr) =>
                tcr?.cisLastUpdate
                    ? moment(tcr?.cisLastUpdate).format("DD/MM/YYYY HH:mm")
                    : "",
            style: { minWidth: "240px", maxWidth: "250px" },
            formattedExportValue: true,
            default: true,
        },
        {
            header: t("company.unRegisteredInCis"),
            sortable: true,
            filter: true,
            default:true,
            field: "unRegisterInCis",
            filterType:"TRISTATECHECKBOX",
            dataType:"boolean",
            style: { minWidth: "200px", maxWidth: "200px" },
        },
        {
            header: "Maintainer Code",
            sortable: true,
            filter: true,
            default:true,
            field: "maintainerCode",
            style: { minWidth: "200px", maxWidth: "200px" },
        },
        {
            header: t("company.unRegisterDate"),
            sortable: true,
            filter: true,
            default:true,
            field: "unRegisterDate",
            body: (tcr) =>
                tcr?.unRegisterDate
                    ? moment(tcr?.unRegisterDate).format("DD/MM/YYYY HH:mm")
                    : "",
            formattedExportValue: true,
            style: { minWidth: "240px", maxWidth: "240px" },
        },


    ]


    const [selectedColumns, setSelectedColumns] = usePersistedState(
        tcrColumns.map((col) => ({ field: col.field, show: !!col.default, ...(col?.persisted ? { persisted: true } : {}) })),
        "tcrColumns2"
    );

    const getTcrsFromCis = () => {
        setLoadingFromCis(true)
        mainAxios.post("/endpoint/v3/getDataFromCis", {
            object: "GetTcrView",
            value: null,
            nuis: company.nuis,
            company: company.id
        },)
            .then(res => {
                setLoadingFromCis(false)
                if (res.data.status) {
                    getTCR({ updatedFilters: filters, currentPage: tableRows.currentPage, rowsPerPage: tableRows.rowsPerPage })
                    toast.success(t("common.actionSuccessfully"), { containerId: "all_toast" })
                }
            })
            .catch(err => {
                setLoadingFromCis(false)
            })
    }



    const headerButtonsLeft = (
        <div className="d-flex align-items-center">
            <ExportCsvPrime
                data={tcrForCsv}
                columns={tcrColumns}
                onClick={() => getTCR({ updatedFilters: filters, exportCsv: true })}
                excludedColumns={["actions"]}
            />
            <CisMessage requestBody={{
                object: "GetCompanyInfoRequestLogV",
                params: {
                    type: "TCR"
                },
                company: company.id
            }} />
            {isSysAdmin&& <div className='ml-4'>{editTcrInCisActions}</div>}
            </div>
    )

    const headerButtonsRight = (<div className='d-flex align-items-end justify-content-end mr-3'>
        <div className="branches-actions">
            <button disabled={loadingFromCis} className="btn btn-sm btn-outline-primary mb-0 mr-2" onClick={() => getTcrsFromCis()}>
                {loadingFromCis ? <Loader size={"xs"} /> : "Get TCR from Cis"}
            </button>
        </div>
        <div className="branches-actions">
            <button className="btn btn-sm btn-outline-primary  mb-0" onClick={() => setTcrDialog({ visible: true, tcr: null })}>
                {t('company.registerTCR')}
            </button>
        </div>
    </div>)


    return (
        <div>
            {

                <Dialog
                    header={tcrDialog?.tcr ? t("selfServices.editTcr") : t('company.registerTCR')}
                    visible={tcrDialog?.visible}
                    onHide={() => setTcrDialog({ visible: false, tcr: null })}
                    style={{ width: "30vw" }}
                    breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                >
                    {tcrDialog?.visible && <RegisterEditTcr tcr={tcrDialog?.tcr} company={company} setCompany={setCompany} onTcrChanged={() => {
                        getTCR({ updatedFilters: filters, currentPage: tableRows.currentPage, rowsPerPage: tableRows.rowsPerPage });
                        setTcrDialog({ visible: false, tcr: null })
                        if (company.id == selectedCompany) getCompany()
                    }} />}

                </Dialog>
            }


            <div className='prime-header-sticky'>
                {!tcrData ? <Loader /> :
                    <PrimeTable
                        reorderableColumns={true}
                        value={tcrData ?? allTcrs}
                        filters={[filters, setFilters]}
                        defaultFilters={defaultFilters}
                        columns={tcrColumns}
                        responsiveLayout="scroll"
                        paginator={true}
                        columnResizeMode="expand"
                        scrollable={true}
                        scrollHeight="580px"
                        showFilters={true}
                        showSelectColumns={true}
                        selectedColumns={[selectedColumns, setSelectedColumns]}
                        filterDisplay={"row"}
                        clearFilters={true}
                        rowReorder={false}
                        headerButtonsRight={ isSysAdmin && headerButtonsRight }
                        headerButtonsLeft={isSysAdmin && headerButtonsLeft }
                        tableRows={[tableRows, setTableRows]}
                        onRemoteDataChange={(remoteData) => {
                            const updatedFilters = remoteData.filters ?? filters
                            const updatedTableData = remoteData.tableRows ?? tableRows
                            let { currentPage, rowsPerPage, sortCriteria } = updatedTableData
                            sortCriteria = sortCriteria ? [sortCriteria] : null
                            getTCR({ updatedFilters, rowsPerPage, currentPage, sortCriteria })
                        }}
                    >
                     {isSysAdmin &&  selectedRowsColumn}
                    </PrimeTable>    
                        }
            </div>
        </div>
        

    )
}

export default Tcrs