import React, { useRef, useEffect } from "react"
import { mainAxios, setAuthToken } from '../../services/mainAxios'
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { useAppContext } from '../../AppContext'
import func from "../../services/functions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faEye, faPaperPlane, faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from './react-simple-captcha'
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import ForgetPassword from "./ForgetPassword"
import { toast } from "react-toastify"
import OtpInput from "react-otp-input"

import authLogo from '../../design/assets/lock-icon1.svg'
import useCountDown from "react-countdown-hook"
import PopOver from "../global/PopOver"
import Lottie from "lottie-react";
import onlineBank from '../../design/assets/ProfiscLogin.json'





const NewLogin = (props) => {
    const { t, i18n } = useTranslation('translations');
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [passwordType, setPasswordType] = React.useState("password")
    const { user: [user, setUser], selectedCompany: [selectedCompany, setSelectedCompany], company: [fullCompany, setFullCompany], getUnitOfmeasure, getStatus } = useAppContext()
    const [forget, setForget] = React.useState(false);

    const defaultState = {
        loggingUser: false,
        userIsLoggedIn: user ? true : false,
        error: null,
        selectedCompany: 0,
        token: null,
        user: user,
        otpSent: 0,
        otpCode: "",
        mfaOptions: [],
        otpJwt: null,
        attempts: 3,
        otpSendLoading: false
    }

    const initialTime = 60 * 1000; // initial time in milliseconds, defaults to 60000
    const interval = 1000;  // interval to change remaining time amount, defaults to 1000

    const [state, setState] = React.useState(defaultState)
    const [captchaState, setCaptchaState] = React.useState({
        captcha: "",
        canLogin: false,
        error: null
    })


    const [timeLeft, { start, pause, resume, reset }] = useCountDown(initialTime, interval);

    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    const restart = React.useCallback(() => {
        const newTime = 180 * 1000;
        start(newTime);
    }, []);


    const handleCaptchaChange = (e) => {
        const captcha = e.target.value
        setCaptchaState(captchaState => ({
            ...captchaState,
            captcha
        }))
    }
    React.useLayoutEffect(() => {
        const resetCaptchaLink = document.querySelector("#reload_href")
        if (!resetCaptchaLink) return
        resetCaptchaLink.innerHTML = `
            <svg 
                aria-hidden="true" 
                focusable="false" 
                data-prefix="fas" 
                data-icon="sync-alt" 
                class="svg-inline--fa fa-sync-alt fa-w-16 " 
                role="img" xmlns="http://www.w3.org/2000/svg" 
                viewbox="0 0 512 512">
                <path 
                    fill="currentColor" 
                    d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z">
                </path>
            </svg>
        `
        loadCaptchaEnginge(6);
    }, [state?.userIsLoggedIn])

    const initData =()=> {
        getUnitOfmeasure();
        getStatus()
    }
    const onLogin = (response, fromOtp) => {
        let content = fromOtp ? response.data.content[0] : response.data
        setAuthToken(content.token)
        let fullUser = content.fullUser

        if (selectedCompany && fullUser.companiesRights.map(company => company.id).includes(parseInt(selectedCompany))) {
            setUser(fullUser)
            const token = content.token
            func.setCookie("token", token)
            getCompany(selectedCompany)
            const refreshToken = response?.headers?.["refresh-token"]
            func.setCookie("refreshToken", refreshToken)
            setAuthToken(refreshToken, "Refresh-Token")
            initData()
            window.location.reload(true);
        }
        else {
            setState(state => ({
                ...state,
                loggingUser: false,
                userIsLoggedIn: true,
                error: null,
                token: content.token,
                user: fullUser
            }))
        }
    }



    const getCompany = (companyId) => {
        mainAxios.post('apiEndpoint/search', {
            object: "GetCompany",
            params: {
                id: companyId
            }
        }).then(res => {
            if (res) {
                setFullCompany(res?.data?.content[0])
            }
        })
    }

    const sendOtp = () => {
        setState((state) => ({
            ...state,
            otpSendLoading: true
        }))

        mainAxios.post('public/generateOtp?mfa=' + state.mfa, {
            username,
        })
            .then((res) => {
                restart()
                setState((state) => ({
                    ...state,
                    otpSendLoading: false
                }))
                if (res.data.status == true) {
                    toast.success("Otp was sent successfully", {
                        containerId: "all_toast",
                    });
                    setState((state) => ({
                        ...state,
                        otpSent: state.otpSent + 1
                    }))
                }
            })
    }
    const otpVerification = (value) => {
        mainAxios.post('/public/validateOtp?otp=' + value, {
            username,
        })
            .then((res) => {
                if (res?.data?.status == true) {
                    setState((state) => ({
                        ...state,
                        otpJwt: null,
                        otpCode: "",
                    }))
                    onLogin(res, true)
                } else {
                    if (res.data?.content?.length == 0) {
                        reset()
                        return
                    }
                    setState((state) => ({
                        ...state,
                        attempts: res?.data?.content[0].attempts
                    }))
                }
            })
    }

    const handleLogin = () => {
        const captchaNeeded = process.env.REACT_APP_CAPTCHA === "false" ? false : true
        if ((captchaNeeded && validateCaptcha(captchaState.captcha.toLowerCase())) || !captchaNeeded) {
            setCaptchaState(captchaState => ({
                ...captchaState,
                error: null
            }))
            setState(state => ({
                ...state,
                loggingUser: true
            }))

            mainAxios.post('public/authenticate', {
                username,
                password,
                isAgent: 0,
                webLogin: true
            }).then(res => {
                if (res.status === 200) {
                    if (!res?.data?.otpJwt) {
                        setState(state => ({
                            ...state,
                            loggingUser: false,
                        }))
                        onLogin(res)
                    } else if (res?.data?.otpJwt) {
                        setState(state => ({
                            ...state,
                            loggingUser: false,
                            otpJwt: res?.data?.otpJwt,
                            mfaOptions: res?.data?.mfaList,
                            mfa: res?.data?.mfa ?? "EMAIL"
                        }))
                        setAuthToken(res.data.otpJwt)
                    }
                }
                else if (res?.response?.status === 423) {
                    setState(state => ({
                        ...state,
                        loggingUser: false,
                        userIsLoggedIn: false,
                        error: t(['toast.manyAttemps'])
                    }))
                }
                else {
                    setState(state => ({
                        ...state,
                        loggingUser: false,
                        userIsLoggedIn: false,
                        error: t(['toast.invalidCredentials'])
                    }))
                }
            })
                .catch(err => {
                    setState(state => ({
                        ...state,
                        loggingUser: false,
                        userIsLoggedIn: false,
                        error: t(['toast.invalidCredentials'])
                    }))
                })
            loadCaptchaEnginge(6);
        }
        else {
            setCaptchaState(captchaState => ({
                ...captchaState,
                error: t(['toast.captchaError']),
                captcha: ""
            }))
        }
    }


    const goToLogin = () => {
        setState(state => ({
            ...state,
            loggingUser: false,
            userIsLoggedIn: false,
            error: null,
            otpJwt: null
        }))
    }

    const handleSelectedCompany = (e) => {
        const selectedCompany = e.target.value
        setState(state => ({
            ...state,
            selectedCompany
        }))
    }

    const onCompanySelected = () => {
        if (state.selectedCompany !== 0) {
            getCompany(state.selectedCompany)
            func.setCookie("token", state.token)
            setUser({
                ...state.user
            })
            setSelectedCompany(state.selectedCompany)
            setState(state => ({
                ...state,
                error: null
            }))
        }
        else {
            setState(state => ({
                ...state,
                error: "U need to choose a company!"
            }))
        }
    }
    const handleOtpCodeChange = (e) => {
        if (e.length == 4) otpVerification(e)
        setState((state) => ({ ...state, otpCode: e }))
    }


    return (
        <div className="overflow-hidden">
            <TransitionGroup component={null}>
                {
                    !forget && !state.userIsLoggedIn && !state?.otpJwt &&
                    // false&&
                    <CSSTransition
                        timeout={400}>
                        <div className="overflow-hidden">
                            <div className="authincation d-flex">
                                <div className="login-aside text-center d-flex flex-column flex-row-auto">
                                    <div className="d-flex flex-column-auto flex-column fade-in-effect">
                                        <div className="text-center mb-3 mb-md-5 pt-0 pt-md-5">
                                            <img src="/Logo-Profisc-2.png" alt="" style={{width:"280px", height:"90px"}} />
                                        </div>
                                        <h2 className="mb-4 text-center">Zgjidhje Smart per Fiskalizimin!</h2>
                                    </div>
                                    <Lottie className="d-none d-md-block aside-image mt-4" animationData={onlineBank} loop={false} />
                                </div>
                                <div
                                    className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-4 p-md-7 mx-auto">
                                    <div className="d-flex justify-content-center h-100 align-items-center">
                                        <div className="authincation-content style-2">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12 tab-content">
                                                    <div id="sign-in" className="auth-form form-validation">
                                                        <form onSubmit={(e) => { e.preventDefault(); handleLogin() }} className="form-validate">
                                                            <h1 className="text-center mb-4 text-black">
                                                                {t('navbar.login')}
                                                            </h1>
                                                            
                                                            <div className="form-group font-weight-bold">
                                                                <label className="form__label">{t('users.username')}*</label>
                                                                <input
                                                                    type="text"
                                                                    className={"form__input form__input--lg form-control py-4" + (state.error ? " border-danger" : " border-white")}
                                                                    placeholder={t('users.username')}
                                                                    value={username}
                                                                    autoComplete="new-password"
                                                                    onChange={(e) => setUsername(e.target.value)}
                                                                    required />
                                                            </div>
                                                            <div className="form-group font-weight-bold position-relative">
                                                                <label className="form__label">{t('users.password')} *</label>
                                                                <input
                                                                    type={passwordType}
                                                                    className={"form__input form__input--lg form-control py-4" + (state.error ? " border-danger" : " border-white")}
                                                                    placeholder={t('users.password')}
                                                                    value={password}
                                                                    autoComplete="new-password"
                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                    required />
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                    className="view-password"
                                                                    onClick={() => setPasswordType(passwordType === "type" ? "password" : "type")} />
                                                            </div>
                                                            <div className="captcha d-flex align-items-start flex-wrap mb-3">
                                                                <LoadCanvasTemplate />
                                                                <div className="col px-0 d-flex flex-wrap mr-2">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control col"
                                                                        style={{ width: 80 }}
                                                                        value={captchaState.captcha}
                                                                        onChange={handleCaptchaChange} />
                                                                </div>
                                                                <div className={"alert alert-danger px-1 py-1  m-0 ml-0 ml-md-2 ml-lg-0 order-3 " + (captchaState.error ? "" : " d-none")}>
                                                                    {captchaState.error}
                                                                </div>
                                                            </div>
                                                            <div className="text-center form-group mb-3">
                                                                <button type="submit" className="btn submit btn-block text-white" style={{backgroundColor: "#007567"}}>
                                                                {t('navbar.login')}
                                                                </button>
                                                            </div>
                                                        </form>
                                                        <div className="mt-3 new-account">


                                                            <div className="d-flex justify-content-center flex-wrap mt-1 p-2" style={{ fontSize: "16px" }}>
                                                                <Link onClick={() => setForget(true)} to={"/forget-password"} className="nav-link p-0">
                                                                    <u>{t("common.forgotPassword")}</u>
                                                                </Link>

                                                            </div>
                                                            <div className={"d-flex justify-content-center justify-content-md-between jusitify-content-lg-between mt-2 mx-3 text-danger" + (state.error ? "" : " d-none")}>
                                                                <p>{state.error}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                }

                {forget && <ForgetPassword />}

            </TransitionGroup>
            <TransitionGroup component={null}>
                {
                    state.otpJwt &&
                    // true && 
                    <CSSTransition
                        className="otp-container"
                        timeout={300}>
                        <div className="otp-container">
                            <div className="otp-login-section mt-3 w-md-100">
                                <img className="auth-logo" src={authLogo} />
                                <h3 className="send-sms-message">{state.otpSent > 0 ? t("invoice.verifysms") : t("invoice.sendsms")}</h3>
                                {state.otpSent > 0 && (
                                    <div className="d-flex mt-4">
                                        <OtpInput
                                            isInputNum={true}
                                            value={state.otpCode}
                                            className=""
                                            onChange={handleOtpCodeChange}
                                            numInputs={4}
                                            inputStyle="otp-input"
                                        />
                                    </div>
                                )}
                                <div className="d-flex align-items-center justify-content-center">
                                    <button
                                        className={`btn btn-primary shadow mb-2 mt-4`}
                                        onClick={sendOtp}
                                        disabled={state.otpSendLoading || (state.otpSent > 0 && minutes >= 2 && minutes > 0)}
                                    >
                                        <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                                        {state.otpSent > 0 ? t("invoice.resendCode") : t("invoice.send")}
                                    </button>
                                    <div className="mt-4">
                                        <PopOver title={t("hints.otpHint")} />
                                    </div>
                                </div>
                                <div className="d-flex mt-3">
                                    <div className="text-muted mr-4">{state.attempts} {t("invoice.attemtpsLeft")}</div>
                                    <div className="time-countdown text-danger"> {minutes}:{seconds < 10 ? "0" + seconds : seconds}</div>
                                </div>

                            </div>
                        </div>
                    </CSSTransition>
                }
            </TransitionGroup>

            <TransitionGroup component={null}>
                {state?.userIsLoggedIn &&
                    <CSSTransition
                        timeout={400}>

                        <div className="authincation d-flex">
                            <div className="login-aside text-center d-flex flex-column flex-row-auto">
                                <div className="d-flex flex-column-auto flex-column">
                                    <a href="#" className="go-back" onClick={(e) => { e.preventDefault(); goToLogin() }}>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </a>
                                    <div className="text-center mb-5 pt-5">
                                    <img src="/Logo-Profisc-2.png" alt="" style={{width:"280px", height:"90px"}} />
                                    </div>
                                    <h2 className="mb-4 text-center">Zgjidhje Smart per Fiskalizimin!</h2>
                                </div>
                                <Lottie className="aside-image mt-4" animationData={onlineBank} loop={false} />
                            </div>

                            <div
                                className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
                                <div className="d-flex justify-content-center h-100 align-items-center">
                                    <div className="authincation-content style-2">
                                        <div className="row no-gutters">
                                            <div className="col-xl-12 tab-content">
                                                <div className="auth-form form-validation">
                                                    <form className="h-100 d-flex flex-column justify-content-between" onSubmit={(e) => { e.preventDefault(); onCompanySelected() }}>
                                                        <h1 className="text-center mb-4 text-black">
                                                            {t('company.selectCompany')}
                                                        </h1>

                                                        <div className="form-group font-weight-bold position-relative">
                                                            <select
                                                                name="companies"
                                                                className={"form-control py-2" + (state.error ? " border-danger" : "")}
                                                                value={state.selectedCompany}
                                                                onChange={(e) => { handleSelectedCompany(e) }}
                                                                required>
                                                                <option value="0" disabled>{t('company.selectCompany')}</option>
                                                                {state.user.companiesRights.map((company, index) => (
                                                                    <option value={company.id} key={index}>{company.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="mt-3 form-text text-danger">
                                                            <p>{state.error ? state.error : ""}</p>
                                                        </div>
                                                        <div className="text-center form-group mb-3">
                                                            <button type="submit" className="btn submit btn-primary btn-block" value={t('company.continue')}>
                                                                {t('company.continue')}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </CSSTransition>
                }
            </TransitionGroup>
        </div>
    )
}

export default NewLogin