import React from 'react'
import { useTranslation } from "react-i18next";

const Forbidden403 = () => {


    const { t, i18n } = useTranslation("translations");

    return (
        <div className="page-403 page-403-body">
            <div className="lock"></div>
            <div className="message">
                <h1 style={{ fontSize: "85px", marginTop: "5px", marginBottom: "5px", color: "#007567", textShadow: "1px 2px 2px #444" }}>403</h1>
                <h1 style={{ fontSize: "45px" }}>{t("users.accessRestrict")}</h1>

            </div>
        </div>
    );
};

export default Forbidden403