import React, { useMemo, useRef, useState } from "react";
import mainAxios from "../../services/mainAxios";
import func from "../../services/functions";
import { useTranslation } from "react-i18next";
import {
  AppContext,
  getStore,
  useAppContext,
  usePersistedState,
} from "../../AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCashRegister,
  faCircleCheck,
  faCompress,
  faCreditCard,
  faExpand,
  faMoneyBill,
  faMoneyBillAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import Loader from "../Layout/Loader";
import { Link } from "react-router-dom";
import countries from "../../services/countries.json";
import invoiceActions from "../../services/invoiceActions.json";
import invoiceStatuses from "../../services/invoiceStatuses.json";
import { toast } from "react-toastify";
import PosDetails from "../../components/MyPos/components/PosDetails";
import PopOver from "../global/PopOver";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import OpenTcrData from "../CashRegister/OpenTcrData";
import swal from "@sweetalert/with-react";
import FinalizeInvoice from "./components/FinalizeInvoice";
import { Dialog } from 'primereact/dialog';
import Swal from "sweetalert2";

const bigDecimal = require("js-big-decimal");
const { round } = func;

const CreatePosTransaction = (props) => {
  const {
    user: [user],
    selectedCompany: [selectedCompany],
    company: [fullCompany]
  } = useAppContext();
  const { t } = useTranslation("translations");
  const firstUpdate = React.useRef(true);
  const location = useLocation();
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [printInvoice, setPrintInvoice] = React.useState(
    location?.state?.printInvoice || false
  );
  const [searchValue, setSearchValue] = React.useState("");
  const [tcrDataOperations, setTcrDataOperations] = useState([])
  const [zeroPriceItems, setZeroPriceItems] = useState({})
  const [reloadCashDeposit, setReloadCashDeposit] = useState(false)
  const [persistedData, setPersistedData] = usePersistedState({ rate: "1", currency: "ALL", businessUnitCode: null, tcrCode: null, profileId: "P1" }, "persistedData");
  const [persistedStatePos, setPersistedStatePos] = usePersistedState(null, "persistedStatePos");
  // const [visibleCashRegister, setVisibleCashRegister] = useState(false)
  const [cashRegisterTcrType,setCashRegisterTcrType]=useState({ actionType:"",isOpen:false })

  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );

  const branchesOptions = company.branches.map((branch) => {
    return {
      label: branch.businUnitCode + " - " + branch.sellerAddress,
      value: branch.businUnitCode + " - " + branch.sellerAddress,
      ...branch
    }
  })

  const defaultPosState = {
    parties: {
      buyer: {
        tin: "",
        name: "",
        address: "",
        country: "Albania",
        town: "",
        buyerIDType: "NUIS",
      },
      seller: {
        tin: "",
        address: "",
        country: "",
        town: "",
        name: "",
        sellerIDType: "NUIS",
      },
    },
    customer: {
      tin: "",
      name: "",
      address: "",
      country: "Albania",
      town: "",
      type: "1",
    },
    type: process.env.REACT_APP_EINVOICE_CODE,
    correctiveInvType: "",
    profileId: persistedData?.profileId ?? "P1",
    number: "",
    issueDate: new Date(),
    dueDate: new Date(),
    invoiceStartDate: "",
    invoiceEndDate: "",
    discount: {
      type: t(["invoice.value"]),
      value: "",
      vat: company.issuerInVat ? "20" : "0",
      vatLabel: null,
    },
    bankAccount: null,
    attachments: [],
    currency: persistedData?.currency ?? "ALL",
    rate: persistedData.rate ?? "1",
    // currency:"1",seperated usePeristed state
    contract: "",
    info: "",
    invoiceTotalWithoutVat: "0",
    invoiceTotalDiscount: "0",
    invoiceDiscountedTotalWithoutVat: "0",
    invoiceTotalVat: "0",
    invoiceTotalWithVat: "0",
    tcrCode: "",
    businessUnitCode: "",
    opCode: user?.userCompanyOperator?.find(
      (uco) => parseInt(uco.company) === parseInt(selectedCompany)
    )?.operator,
    paymentMethodType: "cash",
    items: [],
    einvoice: false,
    paymentMeans: "",
    internalId: "",
    typeOfSelfInvoice: "",
    selfInvoice: false,
    reverseCharge: false,
    iic_ref: "",
    refInvIIC: "",
    refInvIssueDateTime: "",
    hasTransport: false,
    transporter: "",
    vehiclePlate: "",
    destinationAddress: "",
    correctionOfSelfInvoice: false,
    subSequenceDelivType: "",
    source: "MP",
    param1: "",
    param2: "",
    // param3:"",
    // param4:"",
    paymentNote: "",
  };

  const [posState, setPosState] = React.useState(defaultPosState);
  const [code, setCode] = useState("");
  const codeRef = useRef(null);

  const defaultCashRegisterData = {
    tcr: posState.tcrCode,
    initialTotal: 0,
    initialAction: 0,
    withdrawTotal: 0,
    withdrawAction: 0,
    depositTotal: 0,
    depositAction: 0,
  };
  const [cashRegisterData, setCashRegisterData] = useState(
    defaultCashRegisterData
  );

  const tcrList = useMemo(() => {
    let filteredBranch = company.branches.find(
      (branch) =>
        branch.businUnitCode == persistedData?.businessUnitCode ||
        branch.businUnitCode == posState.businessUnitCode
    );
  
    let tcrTypes = filteredBranch?.tcrTypes
      .filter((tcrType) => tcrType?.allowInvoice)
      .sort((a, b) => new Date(b.recordDate) - new Date(a.recordDate)) ?? [];
  
    let tcrList = tcrTypes.map((tcr) => {
      let sellerName = filteredBranch.sellerName;
      return {
        label: tcr.tcrCode + "-" + sellerName,
        value: tcr.tcrCode,
      };
    });
  
    return tcrList.length > 0
      ? [{ label: "Select", value: "" }].concat(tcrList)
      : tcrList;
  }, [selectedCompany, posState?.businessUnitCode]);
  

  const typeOfPaymentMeansNonCash = [
    { label: t("invoice.transfer"), value: "31" },
    { label: t("invoice.account"), value: "42" },
    { label: t("invoice.other"), value: "ZZZ" },
  ];

  const typeOfPaymentMeansCash = [
    { label: "BANKNOTE", value: "10" },
    { label: "BANK CARD", value: "48" },
  ];

  const [state, setState] = React.useState(
    location?.state?.state || {
      categories: null,
      activeCategory: persistedStatePos?.activeCategory ?? null,
      allItems: null,
      filteredItems: null,
    }
  );


  useEffect(() => {
    let branch =
      company.branches.find((branch) => branch.default) ||
      company.branches[0];
  
    let businessUnitCode = branch?.businUnitCode ?? "";
    let tcrCode = branch.tcrTypes
      .filter(tcrType => tcrType.businUnitCode === businessUnitCode && tcrType?.allowInvoice)
      .sort((a, b) => new Date(b.recordDate) - new Date(a.recordDate))[0]?.tcrCode || "";
  
    let persistedBranch = branchesOptions?.find(
      (branch) => branch?.businUnitCode == persistedData?.businessUnitCode
    );
  
    if (persistedData?.tcrCode && tcrList.some((tcr) => tcr?.value == persistedData?.tcrCode)) {
      tcrCode = persistedData.tcrCode;
    }
  
    if (persistedBranch) {
      businessUnitCode = persistedData.businessUnitCode;
      branch = persistedBranch;
    }
  
    const seller = {
      tin: company.nuis,
      address: branch.sellerAddress,
      country: countries.find((country) => country.label === branch.sellerCountry)?.value || "Albania",
      town: branch.sellerTown,
      name: company.name,
      sellerIDType: "NUIS",
    };
  
    setPosState((invoiceState) => ({
      ...invoiceState,
      parties: {
        ...invoiceState.parties,
        seller,
      },
      businessUnitCode,
      tcrCode,
    }));
  
    let allItems = location?.state?.state?.allItems;
    if (!allItems || allItems?.length == 0) {
      mainAxios
        .post("apiEndpoint/search", {
          object: "GetCompanyItems",
          value: "",
        })
        .then((res) => {
          if (res?.data?.status === true) {
            const items = res.data.content;
            const categories = items.reduce(
              (obj, item) => ({
                ...obj,
                [item.category.name]: obj[item.category.name]
                  ? obj[item.category.name] + 1
                  : 1,
              }),
              {}
            );
  
            setState((state) => ({
              ...state,
              allItems: items,
              filteredItems: items,
              categories,
            }));
          }
        });
    }
  }, [selectedCompany]);


  useEffect(() => {
    mainAxios
      .post("/apiEndpoint/reportOnCashDeposit", {
        object: "CashDepositRequest",
        params: {
          sendDateTime: moment().format("YYYY-MM-DD"),
          issuerNUIS: company.nuis,
        },
      })
      .then((res) => {
        setTcrDataOperations(res.data.content);
      });
  }, [reloadCashDeposit, company.nuis]);
  

  useEffect(() => {
    if (posState) {
      const {
        items,
        invoiceTotalWithoutVat,
        invoiceTotalVat,
        invoiceTotalWithVat,
        invoiceTotalDiscount,
        invoiceDiscountedTotalWithoutVat,
      } = func.calculateInvoiceData(posState);
  
      setPosState((invoice) => ({
        ...invoice,
        invoiceTotalWithoutVat,
        invoiceTotalVat,
        invoiceTotalWithVat,
        invoiceTotalDiscount,
        invoiceDiscountedTotalWithoutVat,
        items,
      }));
    }
  }, [
    ...posState.items.map((item) => {
      return `${item.quantity},${item.discountValue},${item.discountType},${item.priceWithVat},${item.priceWithoutVat}`;
    }),
    posState.items.length,
    posState.discount,
  ]);
  
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  
    let filteredItems = [];
    if (searchValue.length === 0) {
      if (state.activeCategory == null) {
        filteredItems = state.allItems;
      } else if (state.activeCategory) {
        filteredItems = state.allItems.filter(
          (item) => item.category.name === state.activeCategory
        );
      }
    } else {
      filteredItems = state.allItems.filter((item) => {
        let itemName = item.name.toLowerCase();
        let searchVal = searchValue.toLowerCase();
        return (
          searchVal !== "" &&
          itemName.includes(searchVal) &&
          (item.category.name === state.activeCategory ||
            state.activeCategory === null)
        );
      });
    }
  
    setState((state) => ({
      ...state,
      filteredItems,
    }));
  }, [searchValue, state.activeCategory]);
  
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  
    if (code?.length < 4) {
      return;
    }
  
    const indicator = setTimeout(() => {
      state.allItems.forEach((item) => {
        if (item.code == code) {
          addRemoveItem(item);
          setCode("");
        }
      });
    }, 400);
  
    return () => {
      clearTimeout(indicator);
    };
  }, [code]);

  React.useEffect(() => {
    if (tcrDataOperations.length == 0) return;
    const result = Object.values(
      tcrDataOperations
        .filter((o) => o.tcr == posState.tcrCode)
        .reduce((a, { tcr, ...props }) => {
          if (!a[tcr]) a[tcr] = Object.assign({}, { tcr, operations: [props] });
          else a[tcr].operations.push(props);
          return a;
        }, {})
    );
  
    if (result.length == 0) {
      setCashRegisterData(defaultCashRegisterData);
    }
  
    let initial = result[0]?.operations?.find(
      (el) => el.operation == "INITIAL"
    );
    let withdraw = result[0]?.operations?.find(
      (el) => el.operation == "WITHDRAW"
    );
    let deposit = result[0]?.operations?.find(
      (el) => el.operation == "DEPOSIT"
    );
  
    setCashRegisterData({
      tcr: posState.tcrCode,
      initialTotal: initial?.total ?? 0,
      initialAction: initial?.actions ?? 0,
      depositTotal: deposit?.total ?? 0,
      depositAction: deposit?.actions ?? 0,
      withdrawTotal: withdraw?.total ?? 0,
      withdrawAction: withdraw?.actions ?? 0,
    });
  }, [posState.tcrCode, tcrDataOperations]);
  
  useEffect(() => {
    if (posState?.items.length > 0) {
      let zeroPrices = {};
      let newItems = posState.items.map((item) => {
        let fullItem = state.allItems.find((i) => i.code == item.code);
        const { priceWvat, priceWovat } = priceByCurrency(fullItem);
        if (priceWovat == 0) zeroPrices[item.code] = true;
        return {
          ...item,
          priceWithVat: priceWvat,
          priceWithoutVat: priceWovat,
          unitPrice: priceWovat,
        };
      });
      setZeroPriceItems(zeroPrices);
      setPosState((state) => ({
        ...state,
        items: newItems,
      }));
    }
  }, [persistedData.currency]);
  

  const convertedValue = posState.items.reduce((sum, item) => {
    return bigDecimal.add(
      bigDecimal.add(
        round(bigDecimal.multiply(item.totalWithoutVat, persistedData.rate), 2),
        round(
          bigDecimal.multiply(
            bigDecimal.multiply(item.totalWithoutVat, persistedData.rate),
            bigDecimal.divide(item.vatCategory, 100)
          ),
          2
        )
      ),
      sum
    );
  }, 0);
  const approveInvoice = ({ invoiceState }) => {
    setLoading(true);
    const { businessUnitCode, tcrCode, ...restPersistedData } = persistedData;
    mainAxios
      .post("endpoint/v2.2/saveOrUpdate", {
        object: "CustomInvoice",
        content: {
          action: invoiceActions[2],
          status: invoiceStatuses[2],
          ...func.parseCustomInvoiceRequest(invoiceState),
          // ...restPersistedData
        },
        nuis: company.nuis,
      })
      .then((res) => {
        swal.close();
        if (res?.data?.status === true) {
          toast.success(t(["toast.successApprovalPOS"]), {
            containerId: "all_toast",
          });
        }
        if(res?.data?.content?.[0]?.id){
          props.history.push({
            pathname: "/pos-transactions/edit/" + res.data.content[0].id,
            state: { state: { ...state }, printInvoice },
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };


  const draftInvoice = async ({ invoiceState }) => {

    setLoading(true);
    const { businessUnitCode, tcrCode, ...restPersistedData } = persistedData;

    mainAxios
      .post("endpoint/v2.2/saveOrUpdate", {
        object: "CustomInvoice",
        content: {
          action: invoiceActions[0],
          status: invoiceStatuses[0],
          ...func.parseCustomInvoiceRequest(invoiceState),
          // ...restPersistedData
        },
        nuis: company.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(t(["toast.draftSaved"]), {
            containerId: "all_toast",
          });
          props.history.push({
            pathname: "/pos-transactions/edit/" + res.data.content[0].id,
            state: { state: { ...state }, printInvoice },
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);

      });
  };

  const handleCategoryChange = (category) => {
    setPersistedStatePos({
      ...persistedStatePos,
      activeCategory: category ?? null,
    });
    setState((state) => ({
      ...state,
      activeCategory: category ?? null,
    }));
  };

  const handleDiscountChange = (key, value, index) => {
  
    const {
      unitPrice,
      discountType: dt,
      discountValue: dv,
      vatCategory: vat,
    } = posState.items[index];
 
    const discountType = key === "discountType" ? value : dt;
    const discountValue = key === "discountValue" ? value : dv;
    let discount;
    let count = func.countDecimals(discountValue);
    if (discountType === "Percentage") {
      if (count > 6) {
        return;
      }
      discount = bigDecimal.multiply(
        unitPrice,
        bigDecimal.divide(discountValue, 100)
      );
    } else {
      if (count > 2) {
        return;
      }
      discount = discountValue;
    }

    const priceWithoutVat = bigDecimal.subtract(unitPrice, discount);

    const priceWithVat =
      vat == 0
        ? unitPrice
        : bigDecimal.multiply(
          unitPrice,
          bigDecimal.add(1, bigDecimal.divide(vat, 100))
        );
    
    setPosState((invoiceState) => ({
      ...invoiceState,
      items: [
        ...invoiceState.items.slice(0, index),
        {
          ...invoiceState.items[index],
          [key]: value,
          priceWithoutVat: parseFloat(priceWithoutVat).toFixed(2),
          priceWithVat: parseFloat(priceWithVat).toFixed(2),
        },
        ...invoiceState.items.slice(index + 1),
      ],
    }));
  };

  const handlePriceWithVatChange = (e, item, index) => {
    let value = e.target.value;
    if (value.split(".")[1]) {
      value = value.split(".");
      value = value[0] + "." + value[1].slice(0, 2);
    }
    if (value.includes("-")) {
      value = value.split("-")[1] || "";
    }

    const {
      vatCategory: vat,
      discountValue,
      discountType,
    } = posState.items[index];
    const priceWithVat = new bigDecimal(value).getValue();
    const priceWithoutVat =
      vat == 0
        ? priceWithVat
        : bigDecimal.divide(
          priceWithVat,
          bigDecimal.add(1, bigDecimal.divide(vat, 100))
        );
    let discount;

    if (discountType === "Percentage") {
      discount = bigDecimal.multiply(
        bigDecimal.divide(
          priceWithoutVat,
          bigDecimal.subtract(1, bigDecimal.divide(discountValue, 100))
        ),
        bigDecimal.divide(discountValue, 100)
      );
    } else {
      discount = discountValue;
    }

    const unitPrice = bigDecimal.add(priceWithoutVat, discount);

    const updatedItems = posState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
          ...item,
          unitPrice: unitPrice,
          priceWithVat,
          priceWithoutVat:priceWithoutVat,
        }
    );

    setPosState((posState) => ({
      ...posState,
      items: updatedItems,
    }));
  };

  const addRemoveItem = (item, quantity = "1", fixedQuantity = false) => {
    let isNumOrEmptyStr = /^$|^[+-]?\d+(\.\d+)?$/.test(quantity);
    if (
      !isNumOrEmptyStr ||
      (fixedQuantity && parseFloat(quantity) < 0) ||
      func.countDecimals(quantity) > 3
    ) {
      return;
    }
    let itemIndex = posState.items.findIndex(
      (currItem) => item.code == currItem.code
    );
    let newItem;
    if (itemIndex >= 0) {
      let item = posState.items[itemIndex];
      if (
        (!fixedQuantity &&
          (quantity == "1" ||
            (quantity == -"1" && parseFloat(item.quantity) > 1))) ||
        fixedQuantity
      ) {
        newItem = {
          ...item,
          quantity: fixedQuantity
            ? quantity
            : bigDecimal.add(item.quantity, quantity.toString()),
        };
        setPosState((posState) => ({
          ...posState,
          items: [
            ...posState.items.slice(0, itemIndex),
            newItem,
            ...posState.items.slice(itemIndex + 1),
          ],
        }));
      } else if (
        (quantity == -"1" && item.quantity == "1") ||
        quantity == "0"
      ) {
        let items = [...posState.items];
        items.splice(itemIndex, 1);
        setPosState((posState) => ({
          ...posState,
          items,
        }));
      }
    } else {
      const { priceWvat, priceWovat } = priceByCurrency(item);
      newItem = {
        code: item.code,
        description: item.vatReason,
        discountType: "Percentage",
        discountValue: "",
        investment: item.investment,
        name: item.name,
        priceWithVat: priceWvat,
        priceWithoutVat: priceWovat,
        quantity: "1",
        totalWithVat: priceWvat,
        totalWithoutVat: priceWovat,
        unitOfMeasureId: item?.unitOfMeasureId,
        unitPrice: priceWovat,
        vatCategory: item.vatCategory,
        vatLabel: item?.vatReason == "" ? null : item?.vatReason,
      };
      setPosState((posState) => ({
        ...posState,
        items: [...posState.items, newItem],
      }));

      if (newItem.priceWithoutVat == 0) {
        setZeroPriceItems((state) => ({
          ...state,
          [newItem.code]: true,
        }));
      }
    }
  };

  const handleChange = (key, value) => {
    setPosState((posState) => ({
      ...posState,
      [key]: value,
    }));
  };
  
  const handlePaymentMean = async (value, paymentMethodType) => {
    const { issueDate, tcrCode, businessUnitCode } = posState
    const issDate = moment(issueDate).endOf('day')
    const today = moment().endOf('day')

    if (posState?.parties?.buyer?.buyerIDType !== "NUIS" && paymentMethodType === "cash" &&((posState.currency =="ALL" && (parseFloat(posState?.invoiceTotalWithVat) > 500000)) || (posState.currency !== "ALL" && parseFloat(posState?.invoiceTotalWithVat) > 5000) )) {
      const confirmation = await Swal.fire({
        text: t("toast.amountExceedesAlert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007567",
        confirmButtonText: t("OK"),
        cancelButtonText: t("invoice.backToInvoice"),
      });

      if (!confirmation.isConfirmed) {
        return;
      }
    }

    if (tcrCode?.length === 0) {
      toast.error(t(["toast.tcrCode"]), {
        containerId: "all_toast",
      });
      return;
    }
    if (businessUnitCode?.length === 0) {
      toast.error(t(["toast.bussinUnitCode"]), {
        containerId: "all_toast",
      });
      return;
    }

    if (issDate > today) {
      toast.error(t(["toast.issueDateErr"]), {
        containerId: "all_toast",
      });
      return;
    }
    if (posState.items.length == 0) {
      toast.error("You need to chose at least one item.", {
        containerId: "all_toast",
      });
      return;
    }
    // if (!posState?.parties.buyer?.tin) {
    //   toast.error("Please complete customer information", {
    //     containerId: "all_toast",
    //   });
    //   return;
    // }
    if (
      posState?.parties?.buyer?.buyerIDType === "NUIS" &&
      posState?.paymentMethodType === "cash" &&
      posState?.invoiceTotalWithVat > 150000
    ) {
      toast.error(t(["toast.errorPos"]), {
        containerId: "all_toast",
      });
      return;
    }

    let itemPriceValidation = true;
    posState.items.forEach((item) => {
      if (item.priceWithVat == 0) {
        itemPriceValidation = false;
        return;
      }
    });
    if (!itemPriceValidation) {
      toast.error("Item price must not be 0", {
        containerId: "all_toast",
      });
      return;
    }

    swal(
      <FinalizeInvoice
        swal={swal}
        fullCompany={fullCompany}
        approveInvoice={approveInvoice}
        posState={{ ...posState, paymentMeans: value, paymentMethodType }}
      />,
      {
        className: "swal-pos",
        buttons: false,
        closeOnClickOutside: false,
      }
    );
    handleChange("paymentMeans", value);
  };


  const priceByCurrency = (item) => {
    let priceWvat = item?.priceWvatAll?.toString() ?? "0";
    let priceWovat = item?.priceWovatAll?.toString() ?? "0";
    let currency = "L";
    if (persistedData.currency == "EUR") {
      priceWvat = item?.priceWvatEur?.toString() ?? "0";
      priceWovat = item?.priceWovatEur?.toString() ?? "0";
      currency = "€";
    } else if (persistedData.currency == "USD") {
      priceWvat = item?.priceWvatUsd?.toString() ?? "0";
      priceWovat = item?.priceWovatUsd?.toString() ?? "0";
      currency = "$";
    }
    return { priceWvat, priceWovat, currency };
  };

  const { allItems, filteredItems, activeCategory, categories } = state;
  const { paymentMethodType, paymentMeans } = posState;

  return (

    <div
      className={classnames({
        "full-screen": isFullScreen,
        "py-2": !isFullScreen,
        "pb-3": isFullScreen,
      })}
    >
      <div className="row my-pos">
        <PosDetails
          posState={posState}
          setPosState={setPosState}
          persistedData={persistedData}
          setPersistedData={setPersistedData}
          branchesOptions={branchesOptions}
          tcrList={tcrList}
          location={location}
        />
        <Dialog header={cashRegisterTcrType?.actionType}  visible={cashRegisterTcrType?.isOpen} style={{ width: '45vw' }} onHide={() => setCashRegisterTcrType({isOpen:false})}>
        {cashRegisterTcrType?.isOpen &&<OpenTcrData
          onClose={()=>{setCashRegisterTcrType(null)}}
          onChange={()=>{setReloadCashDeposit((state)=>!state)}}
          data={{
            ...cashRegisterData,
            actionType:cashRegisterTcrType?.actionType,
            businUnitCode: posState?.businessUnitCode,
          }}
        />}
      </Dialog>
        <div className="col-12 p-0">
          <div className="row">
            <div className="col-md-8">
              <div className="shadow border-0">
                <div className="search-items d-flex justify-content-between align-items center">
                  <div className="col-9">
                    <input
                      id="inputID"
                      type="text"
                      className="search-items-input"
                      placeholder="Search items..."
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                  <div className="col-3 d-flex align-items-center mr-5">
                    <input
                      type="text"
                      id="code"
                      name="code"
                      ref={codeRef}
                      className="form-control"
                      placeholder={t("item.scanCode")}
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    />
                    <PopOver
                      customStyle={{
                        borderRadius: "40%",
                        width: "20px",
                        height: "20px",
                        transform: "scale(0.80)",
                        backgroundColor: "#343a40",
                        color: "white",
                      }}
                      title={t("hints.scanItem")}
                    />
                  </div>
                </div>
                <div className="card card-body p-0">
                  {allItems !== null ? (
                    <div className="row">
                      <div className="col-md-3 p-0 fixed-content">
                        <div className="categories">
                          <button
                            className={classnames("category", {
                              active: activeCategory === null,
                            })}
                            onClick={() => handleCategoryChange(null)}
                          >
                            All
                            <span className="item-count">
                              {allItems.length}
                            </span>
                          </button>
                          {Object.entries(categories).map(
                            ([category, count], index) => (
                              <button
                                key={index}
                                className={classnames("category", {
                                  active: activeCategory === category,
                                })}
                                onClick={() => handleCategoryChange(category)}
                              >
                                {category}
                                <span className="item-count">{count}</span>
                              </button>
                            )
                          )}
                        </div>
                      </div>
                      <div className="col-md-9 p-0">
                        <div className="items fixed-content">
                          <div className="row pl-1 pt-1 m-0">
                            {filteredItems.map((item) => {
                              let prices = priceByCurrency(item);
                              return (
                                <div className="col-6 col-sm-4 col-md-3 p-0 pr-1 pb-1">
                                  <button
                                    key={item.id}
                                    className="item"
                                    onClick={() => addRemoveItem(item)}
                                  >
                                    <div className="text-left truncate">
                                      {item.name}
                                    </div>
                                    <div className="item-price">
                                      {prices.priceWvat}
                                      {prices.currency}
                                    </div>
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="card shadow border-0">
                <div className="card-header bg-white font-weight-bold invoice-header">
                  <div className="">Invoice</div>
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="printInvoice"
                      name="printInvoice"
                      checked={printInvoice}
                      onChange={(e) => setPrintInvoice(e.target.checked)}
                    />
                    <label
                      style={{ paddingTop: "3px", paddingLeft: "2px" }}
                      htmlFor="printInvoice"
                      className="custom-control-label"
                    >
                      Preview {"&"} Print Invoice
                    </label>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-center card-header flex-wrap"
                  style={{ padding: "10px 0px" }}
                >
                  <>
                    <div className="text-primary">Operator Code:</div>{" "}
                    <div className="d-flex ml-1 mr-3"> {posState?.opCode} </div>
                    <div className="text-primary">TCR:</div>{" "}
                    <div className="d-flex ml-1 mr-3">
                      {" "}
                      {posState?.tcrCode}{" "}
                    </div>
                    <div className="text-primary">Branches:</div>{" "}
                    <div className="d-flex ml-1">
                      {posState?.businessUnitCode}
                    </div>
                  </>
                </div>

                <div className="card-body p-0">
                  <div className="total-items fixed-content">
                    {posState.items.length > 0 ? (
                      <div>
                        <div className="d-flex justify-content-center mb-2 px-4">
                          <div className="flex-4">{t("pos.item")}</div>
                          <div className="flex-4 ml-2">
                            {t("item.quantity")}
                          </div>
                          <div style={{ flex: 3 }}>Item Discount</div>
                          <div className="flex-4 text-right mr-3">
                            {t("pos.price")}
                          </div>
                        </div>

                        {posState.items.map((item, index) => (
                          <div
                            key={item.id}
                            className={`d-flex pos-item justify-content-between align-items-center my-1 px-4`}

                          >
                            <div className="flex-4">
                              <div className="item-name">{item.name}</div>
                              <div className="d-flex align-items-end flex-nowrap">
                                <div
                                  className="d-flex align-self-center"
                                  style={{ maxWidth: 60 }}
                                >
                                  <div>{item.quantity} </div>
                                  <div className="mx-1">x </div>
                                </div>
                                {!zeroPriceItems[item?.code] ? (
                                  <div className="mr-1">
                                    {func.formatNumber(item.priceWithVat)}
                                  </div>
                                ) : (
                                  <div className="text-center mr-1 mt-1">
                                    <input
                                      style={{ height: 32, width: 80 }}
                                      type={"number"}
                                      min="0.01"
                                      name="priceWithVat"
                                      autoFocus={true}
                                      className="form-control text-center posPriceWithVat"
                                      placeholder="E.g. 100"
                                      value={item.priceWithVat}
                                      onChange={(e) =>
                                        handlePriceWithVatChange(e, item, index)
                                      }
                                    />
                                  </div>
                                )}
                                <div className="mr-2 align-self-center">
                                  {persistedData.currency}
                                </div>
                              </div>
                            </div>
                            <div className="quantity buttons_added justify-content-start flex-4">
                              <input
                                type="button"
                                defaultValue="-"
                                className="minus"
                                onClick={() => addRemoveItem(item, -"1")}
                              />
                              <input
                                type="number"
                                value={item.quantity}
                                step={1}
                                name="quantity"
                                title="Qty"
                                className="input-text qty text"
                                size={4}
                                onChange={(e) =>
                                  addRemoveItem(item, e.target.value, true)
                                }
                              />
                              <input
                                type="button"
                                defaultValue="+"
                                className="plus"
                                onClick={() => addRemoveItem(item, "1")}
                              />
                            </div>
                            <div className="input-group custom-discount flex-nowrap flex-4">
                              <input
                                type="number"
                                id="discount"
                                name="discount"
                                className="form-control"
                                value={item.newDiscount}
                                min={0}
                                onKeyDown={(e) => {
                                  if (e.key === '-' || e.key === 'e') {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) =>
                                  handleDiscountChange(
                                    "discountValue",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-primary"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  style={{ zIndex: "1", cursor: "auto" }}
                                >
                                  {item.discountType == "Percentage"
                                    ? "%"
                                    : "💰︎"}
                                </button>
                                <div className="dropdown-menu">
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleDiscountChange(
                                        "discountType",
                                        "Percentage",
                                        index
                                      );
                                    }}
                                  >
                                    {t("invoice.percentage")}
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleDiscountChange(
                                        "discountType",
                                        "Value",
                                        index
                                      );
                                    }}
                                  >
                                    {t("invoice.value")}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center flex-4">
                              <div className="total-item-price">
                                {func.formatNumber(item.totalWithVat)}{" "}
                                {persistedData.currency}
                              </div>
                              <FontAwesomeIcon
                                className="text-danger ml-2"
                                icon={faTimesCircle}
                                size={"1x"}
                                style={{ cursor: "pointer" }}
                                onClick={() => addRemoveItem(item, 0)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <h4 className="text-center mt-5">No items added yet!</h4>
                    )}
                  </div>
                  <div className="invoice-totals">
                    <div className="invoice-totals-group">
                      <div className="invoice-total-type">
                        {t("invoice.totalNoVat")}
                      </div>
                      <div className="invoice-total-value">
                        {func.getFormattedMoneyValue(
                          posState?.invoiceTotalWithoutVat
                        )}{" "}
                        {persistedData.currency}
                      </div>
                    </div>
                    <div className="invoice-totals-group">
                      <div className="invoice-total-type">
                        {t("invoice.totalDiscount")}
                      </div>
                      <div className="invoice-total-value">
                        {func.getFormattedMoneyValue(
                          posState.invoiceTotalDiscount
                        )}{" "}
                        {persistedData.currency}
                      </div>
                    </div>
                    <div className="invoice-totals-group">
                      <div className="invoice-total-type">
                        {t("invoice.totalVat")}
                      </div>
                      <div className="invoice-total-value">
                        {func.getFormattedMoneyValue(posState?.invoiceTotalVat)}{" "}
                        {persistedData.currency}
                      </div>
                    </div>
                    <div className="invoice-totals-group">
                      <div className="invoice-total-type">
                        Total {persistedData.currency}{" "}
                        {t("item.unitPriceVatSmall")}
                      </div>
                      <div className="invoice-total-value">
                        {func.getFormattedMoneyValue(
                          posState.invoiceTotalWithVat
                        )}{" "}
                        {persistedData.currency}
                      </div>
                    </div>
                    <div className="invoice-totals-group">
                      <div className="invoice-total-type font-weight-bold">
                        {t("invoice.totalPay")}({persistedData.currency})
                      </div>
                      <div className="invoice-total-value">
                        {func.getFormattedMoneyValue(
                          posState.invoiceTotalWithVat
                        )}{" "}
                        {persistedData.currency}
                      </div>
                    </div>
                    {persistedData.currency !== "ALL" && (
                      <div className="invoice-totals-group">
                        <div className="invoice-total-type font-weight-bold">
                          {t("invoice.totalPay")} (LEK)
                        </div>
                        <div className="invoice-total-value">
                          {func.getFormattedMoneyValue(convertedValue)} LEK
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center payment-options mt-4 m-0 shadow">
          <div className="ml-md-auto column-5">
            <button
              style={{ backgroundColor: "#b33771" }}
              type="button"
              className="btn posbutton payment-btn"
              onClick={() => {
                setCashRegisterTcrType({ actionType: "INITIAL", isOpen:true });
              }}
            >
              <FontAwesomeIcon icon={faCashRegister} className="mr-1" />
              CASH INITIAL
            </button>
          </div>
          <div className="column-5">
            <button
              style={{ backgroundColor: "#4a1326" }}
              type="button"
              className="btn posbutton payment-btn"
              onClick={() => {
                setCashRegisterTcrType({ actionType: "DEPOSIT",isOpen:true });
              }}
            >
              <FontAwesomeIcon icon={faCashRegister} className="mr-1" />
              CASH DEPOSIT
            </button>
          </div>
          <div className="column-5">
            <button
              style={{ backgroundColor: "#319398" }}
              type="button"
              className="btn posbutton payment-btn"
              onClick={() => {
                setCashRegisterTcrType({ actionType: "WITHDRAW",isOpen:true });
              }}
            >
              <FontAwesomeIcon icon={faCashRegister} className="mr-1" />
              CASH WITHDRAW
            </button>
          </div>

          <div className="column-5">
            <button
              style={{ backgroundColor: "#af3b3b" }}
              type="button"
              name="transfer"
              onClick={() =>
                handlePaymentMean(typeOfPaymentMeansNonCash[0].value, "noncash")
              }
              className={`btn posbutton payment-btn`}
            >
              {loading && paymentMeans == typeOfPaymentMeansNonCash[0].value ? (
                <Loader size="xs" color="white" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
                  {typeOfPaymentMeansNonCash[0].label.toUpperCase()}
                </>
              )}{" "}
            </button>
          </div>
          <div className="column-5">
            <button
              style={{ backgroundColor: "#5f27cd" }}
              type="button"
              name={"account"}
              onClick={() =>
                handlePaymentMean(typeOfPaymentMeansNonCash[1].value, "noncash")
              }
              className={`btn posbutton payment-btn`}
            >
              {loading && paymentMeans == typeOfPaymentMeansNonCash[1].value ? (
                <Loader size="xs" color="white" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
                  {typeOfPaymentMeansNonCash[1].label.toUpperCase()}
                </>
              )}{" "}
            </button>
          </div>
          <div className="column-5">
            <button
              style={{ backgroundColor: "#607d8b" }}
              type="button"
              name={"other"}
              onClick={() =>
                handlePaymentMean(typeOfPaymentMeansNonCash[2].value, "noncash")
              }
              className={`btn posbutton payment-btn`}
            >
              {loading && paymentMeans == typeOfPaymentMeansNonCash[2].value ? (
                <Loader size="xs" color="white" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
                  {typeOfPaymentMeansNonCash[2].label.toUpperCase()}
                </>
              )}{" "}
            </button>
          </div>
          <div className="column-5">
            <button
              style={{ background: "#0984e3" }}
              type="button"
              name="bankCard"
              onClick={() => handlePaymentMean(typeOfPaymentMeansCash[1].value, "cash")}
              disabled={paymentMethodType == "noncash"}
              className={`btn posbutton payment-btn`}
            >
              {loading && paymentMeans == typeOfPaymentMeansCash[1].value ? (
                <Loader size="xs" color="white" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faMoneyBill} className="mr-1" />{" "}
                  {typeOfPaymentMeansCash[1].label.toUpperCase()}
                </>
              )}{" "}
            </button>
          </div>
          <div className="column-5">
            <button
              style={{ background: "#007567" }}
              type="button"
              name="bankNote"
              onClick={() => handlePaymentMean(typeOfPaymentMeansCash[0].value, "cash")}
              disabled={paymentMethodType == "noncash"}
              className={`btn posbutton payment-btn`}
            >
              {loading && paymentMeans == typeOfPaymentMeansCash[0].value ? (
                <Loader size="xs" color="white" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faMoneyBill} className="mr-1" />{" "}
                  {typeOfPaymentMeansCash[0].label.toUpperCase()}
                </>
              )}{" "}
            </button>
          </div>
          <div className="column-5">
            <button
              style={{ backgroundColor: "#FF5A36" }}
              type="button"
              className="btn posbutton"
              disabled={posState.items.length === 0}
              onClick={() =>
                setPosState((state) => {
                  return {
                    ...defaultPosState,
                    paymentMethodType: state.paymentMethodType,
                    parties: {
                      ...state.parties,
                      buyer: { ...state.parties.buyer },
                    },
                  };
                })
              }
            >
              <FontAwesomeIcon icon={faTimesCircle} className="mr-1" /> CLEAR
            </button>
          </div>

          {posState?.items?.length > 0 && <div className="column-5 ml-auto">
            <button
              style={{ background: "#49cb8f", float: "right" }}
              type="button"
              name="bankNote"
              onClick={() => (draftInvoice({ invoiceState: posState }))}
              // disabled={paymentMethodType == "noncash"}
              className={`btn posbutton payment-btn`}
            >
              <FontAwesomeIcon icon={faCircleCheck} className="mr-1" />
              {t("common.save")}
            </button>
          </div>}
          <div className="ml-auto mt-3 column-5">
            <button
              className="btn btn-info mr-4"
              style={{ float: "right" }}
              onClick={() => setIsFullScreen((isFullScreen) => !isFullScreen)}
            >
              {isFullScreen ? (
                <FontAwesomeIcon icon={faCompress} />
              ) : (
                <FontAwesomeIcon icon={faExpand} />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default CreatePosTransaction;
