import { faEdit, faExclamationTriangle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Layout/Loader";
import mainAxios from "../../../services/mainAxios";
import { useTranslation, withTranslation } from "react-i18next";
import AlertCertificate from "../../global/AlertCertificate";
import { useAppContext, usePersistedState } from "../../../AppContext";
import CellInput from "../../global/CellInput";
import PrimeTable from "../../PrimeTable/PrimeTable";
import ExportCsvPrime from "../../global/ExportCsvPrime";
import AddUser from "./AddUser";
import { Dialog } from "primereact/dialog";
import { customStyles } from "../../../design/selectStyle";
import ReactSelectPagination from "../../global/ReactSelectPagination";
import TooltipOverlay from "../../global/TooltipOverlay";

const moment = require("moment");

const Users = ({ companyId }) => {
  const { selectedCompany: [selectedCompany] } = useAppContext();
  const [users, setUsers] = React.useState(null);
  const [roles, setRoles] = useState({ purchaseRoles: [], profiscRoles: [] })
  const [usersForCsv, setUsersForCsv] = useState([]);
  const { t, i18n } = useTranslation("translations");
  const [openAddUser, setOpenAddUser] = useState(false)
  const defaultFilters = { params: {} }

  const defaultSortCriteria={
    field: "recordDate",
    direction: "DESC"
  }

  const [filters, setFilters] = React.useState(defaultFilters);
  const [tableRows, setTableRows] = useState({
    totalRows: 0,
    rowsPerPage: 10,
    currentPage: 1,
    sortCriteria:defaultSortCriteria
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [companiesPagination,setCompaniesPagination]=useState({
    totalRows: 0,
    pageSize: 10,
    pageNumber: 1,
  })

  const [params, setParams] = useState({
    companyId: companyId ?? null,
    isLoadingCompanies:false,
    companies:[]
  })

  React.useEffect(() => {
    getUsersResults(10, 1);
  }, [selectedCompany, params?.companyId, companyId]);

  const getUsersResults = ({ updatedFilters, rowsPerPage = 10, currentPage = 1, exportCsv = false, userLiveSearch = "" , sortCriteria = [ defaultSortCriteria ]} = {}) => {
    let compId = params.companyId
    const {"userCompanyOperator.operator": userCompanyOperatorOperator,operatorCode,...restFilters}=updatedFilters?.params ?? {}

    let requestBody = {
      object: compId ? "GetUserOperatorView" : "GetFullUser",
      value: null,
      params: {
        userLiveSearch,
        ...(compId === "" ? {} : { company: compId?.toString(), }),
        ...restFilters,
        ...(compId ? { operatorCode } : {"userCompanyOperator.operator":userCompanyOperatorOperator}),
      },
      pagination: exportCsv ? null : {
        pageSize: rowsPerPage,
        pageNumber: currentPage,
      },
      sortCriteria
    };

    return mainAxios.post("apiEndpoint/search", requestBody).then((res) => {
      if (res?.data?.status === true) {
        if (exportCsv) {
          // const formatedContent = res.data.content.map(user => ({
          //   ...user,
          //   phone: user.phone ? `'${user.phone}` : "",
          // }));
          setUsersForCsv(res.data.content);
          return res.data.content;
        } else {
          setUsers(res.data.content);
          setTableRows((tableRows) => ({
            ...tableRows,
            totalRows: res?.data?.totalSize,
          }));
        }
      }
      return null
    })
      .catch(err => {
        return null
      })
  };


  const mfaOptions = [
    {
      value: '3',
      label: "Disabled",
    },
    {
      value: '1',
      label: "Email",
    },
    {
      value: '2',
      label: "Sms",
    },
  ];


  useEffect(() => {
    mainAxios.post('apiEndpoint/search', {
      object: "GetRoles",
      value: null,
      type: "basic"
    }).then(res => {
      if (res.data.status) {
        const [profiscRoles = [], purchaseRoles = []] = res.data?.content ?? []
        setRoles({ purchaseRoles, profiscRoles })
      }
    })
  }, [])

  const companiesList=(row)=>{
    return (row?.companiesRights?.map(cr=>cr.name)??[]).join(",")
  }

  const columns = [
    {
      header: t(["home.actions"]),
      field: "actions",
      default:true,
      style: { minWidth: "150px", maxWidth: "150px" },
      body: (user) => (
        <>
          <Link to={"/users/edit/" + user.id} className="nav-link p-0">
            <FontAwesomeIcon icon={faEdit} className="mr-1" />
            {t("company.edit")}
          </Link>
        </>
      ),
    },
    {
      header: t(["users.firstName"]),
      field: "firstName",
      filter: true,
      default:true,
      sortable: true,
      style: { minWidth: "180px", maxWidth: "180px" },
      body: (invoice) => <CellInput value={invoice?.firstName ?? ""} />

    },
    {
      header: t(["users.lastName"]),
      field: "lastName",
      filter: true,
      default:true,
      sortable: true,
      style: { minWidth: "180px", maxWidth: "180px" },
      body: (invoice) => <CellInput value={invoice?.lastName ?? ""} />

    },
    {
      header: t(["users.username"]),
      field: "username",
      default:true,
      style: { minWidth: "180px", maxWidth: "180px" },
      body: (invoice) => <CellInput value={invoice?.username ?? ""} />,
      sortable: true,
      filter: true
    },
    {
      header: t(["invoice.Operator"]),
      field: "userCompanyOperator.operator" ,
      sortable: true,
      filter:true,
      default:true,
      filterConfig:{
        field: params?.companyId ?  "operatorCode" : "userCompanyOperator.operator",
        splitKey:false,
        like:false,
      },
      formattedExportValue: true,
      body: (user) =>params?.companyId ? user?.operatorCode : user.userCompanyOperator?.[0]?.operator ?? "",
        // (user?.userCompanyOperator?.[0]?.operator ?? "")
    },
    {
      header: t(["company.active"]),
      field: "active",
      default:true,
      filter: true,
      filterType: "TRISTATECHECKBOX",
      filterConfig: {
        field: "isActive"
      },
      style: { minWidth: "100px", maxWidth: "200px" },
      dataType: "boolean",
      format:(row)=>row?.active?"TRUE":"FALSE",
    },
    {
      header: t(["users.role"]),
      field: "role.name",
      filter: true,
      filterType: "DROPDOWN",
      formattedExportValue: true,
      like:true,
      default:true,
      filterConfig: {
        options: roles.profiscRoles,
        optionLabel: "name",
        optionValue: "name",
        splitKey: false,
        showReset: false
      },
      body:(row)=> row?.role?.name ,
      style: { minWidth: "150px", maxWidth: "150px" },
      sortable: true,
    },
    {
      header: t("users.purchaseRoles"),
      field: "purchaseRole.name",
      filter: true,
      default:true,
      filterType: "DROPDOWN",
      formattedExportValue: true,
      filterConfig: {
        options: roles.purchaseRoles,
        optionLabel: "name",
        optionValue: "name",
        splitKey: false,
        showReset: false
      },
      body:(row)=> row?.purchaseRole?.name ,
      sortable: true,
      style: { minWidth: "200px", maxWidth: "200px" }
    },
    {
      header: "Email",
      field: "email",
      filter: true,
      default:true,
      style: { minWidth: "200px", maxWidth: "200px" },
      body: (user) => <CellInput value={user?.email ?? ""} width={190} onFocus={(event) => event.target.select()} />,
      sortable: true,
    },
    {
      //per tu bo check nqs duhet heq ose jo, pysim zemren
      header: t(["home.dateActivProfisc"]),
      field: "recordDate",
      style: { minWidth: "240px", maxWidth: "250px" },
      filter: true,
      sortable: true,
      default:true,
      filterConfig: {
        field: "userRecordDate",
      },
      filterType: "DATE",
      sortable: true,
      body: (user) =>
        user.recordDate
          ? moment(user.recordDate).format("DD/MM/YYYY HH:mm")
          : "",
      formattedExportValue: true,
    },
    {
      header: t("users.reciveAdministrativeNotification"),
      filter: true,
      default:true,
      field: "receiveAdministrativeNotification",
      filterType: "TRISTATECHECKBOX",
      style: { minWidth: "230px", maxWidth: "250px" },
      dataType: "boolean",
      format:(row)=> row?.receiveAdministrativeNotification ? "TRUE" : "FALSE",

    },
    {
      header: t("users.mfaType"), 
      field: "mfa",
      style: { minWidth: "200px", maxWidth: "200px" },
      sortable: true,
      filter: true,
      default:true,
      filterType: "DROPDOWN",
      filterConfig: {
        options: mfaOptions,
        showReset:false
      },
      formattedExportValue:true,
      body: (user) => (mfaOptions.find(mfaV => mfaV?.value == user?.mfa)?.label ?? ''),
    },
    {
      header: t(["users.phone"]),
      field: "phone",
      style: { minWidth: "150px", maxWidth: "150px" },
      sortable: true,
      default:true,
      body: (user) => (user.phone ? user.phone : ""),
    },
    {
      header: t("users.apisource"),
      field: "apiSource",
      style: { minWidth: "150px", maxWidth: "150px" },
      sortable: true,
      filter:true,
      default:true,
      filterType: "TRISTATECHECKBOX",
      filterConfig:{
        field:"isApiSource",
      },
      dataType: "boolean",
      format:(row)=>row?.apiSource?"TRUE":"FALSE",
    },
    {
      header: t("purchases.warnings"),
      field: "expiresThisMonth",
      style: { minWidth: "220px", maxWidth: "250px" },
      filter: false,
      default:true,
      body: (user) => (
        user?.expiresThisMonth
          ? <div style={{ backgroundColor: '#ffc107', color: '#664d03', padding: '2px 7px', borderRadius: '5px', fontSize: "13px", fontWeight: "500", display: 'inline-flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            {t(["company.expiresWarning"])}
          </div>
          : null
      ),
    },
    {
      header: t("navbar.companies"),
      field: "companies",
      style: { minWidth: "250px", maxWidth: "250px" },
      sortable: false,
      filter:false,
      default:true,
      body:(row)=><TooltipOverlay title={companiesList(row)}><div  style={{maxWidth:250, cursor:"pointer",overflow:"hidden",maxLines:1,whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{companiesList(row)}</div></TooltipOverlay>,
      format:(row)=>companiesList(row),
    },

  ];
    const [selectedColumns, setSelectedColumns] = usePersistedState(
        columns.map((col) => ({ field: col.field, show: !!col.default, ...(col?.persisted ? { persisted: true } : {}) })),
        "userColumns"
    );

  const getFilteredResults = ({pageSize=10, pageNumber = 1,searchQuery,loadMore}={}) => {
    let requestBody = {
        object: "GetSimpleCompany",
        value: null,
        params: {
          companyLiveSearch:searchQuery,
          // ...filters
        },
        pagination:{
            pageSize,
            pageNumber,
        },
        };
    return mainAxios.post('apiEndpoint/search',
    requestBody
    ).then(res => {
        if(res?.data?.status === true) {
          let companies = res.data.content
          setCompaniesPagination({
            pageNumber,
            pageSize,
            totalRows: res?.data?.totalSize,
          })
          setParams((params)=>({...params,companies:loadMore?(params.companies ?? []).concat(companies):companies}))
        return companies
        }
        return []
    })
    .catch(err=>{
      return []
    })
}

const handleCompanyChange=(value)=>{
  setParams(params=>({
    ...params,
    companyId:value?.id ?? null,
    searchQuery:null,
  }))
  setFilters((filters)=>{
    const {"userCompanyOperator.operator": userCompanyOperatorOperator,operatorCode,...restFilters}=filters ?? {}
    return {
      ...restFilters,
      ...(value?.id ? { operatorCode } : {"userCompanyOperator.operator":userCompanyOperatorOperator}),
    }
  })
  if(value===null){
    getFilteredResults({pageNumber:1,pageSize:10,searchQuery:""})
  }

}


useEffect(()=>{
  getFilteredResults({pageNumber:1,pageSize:10})
},[])


  if (!users) return <Loader />

  const addUser = (
    <div className="page-actions mr-3">
      <Link onClick={(e) => { e.preventDefault(); setOpenAddUser(true) }} to="/users/add" className="btn btn-sm btn-primary">
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        {t("subscription.add")}
      </Link>
    </div>
  )
  const exportCertficate = (
    <ExportCsvPrime
      data={usersForCsv}
      columns={columns}
      onClick={() => getUsersResults({updatedFilters:filters, exportCsv: true })}
      excludedColumns={["actions"]}
    />
  )



  const table = ({ tableProps }) => {

    return <>
      <Dialog
        header={t('users.add')}
        visible={openAddUser}
        onHide={() => setOpenAddUser(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}

      >
        <AddUser
          onUserAdded={(user) => { getUsersResults({ rowsPerPage: 10, currentPage: 1 }); setOpenAddUser(false) }}
        />
      </Dialog>
      <PrimeTable
        globalFilterValue={[searchQuery, setSearchQuery]}
        stripedRows={false}
        tableRows={[tableRows, setTableRows]}
        value={users ?? []}
        columns={columns}
        showFilters={true}
        filterDisplay={"row"}
        responsiveLayout="scroll"
        scrollHeight="580px"
        reorderableColumns={true}
        filters={[filters, setFilters]}
        defaultFilters={defaultFilters}
        showHeader={true}
        showGlobalSearch={false}
        dataKey="id"
        onRemoteDataChange={(remoteData) => {
          const updatedFilters = remoteData.filters ?? filters
          const updatedTableData = remoteData.tableRows ?? tableRows
          let { currentPage, rowsPerPage,sortCriteria } = updatedTableData
          sortCriteria=sortCriteria?[sortCriteria]:null
          getUsersResults({ updatedFilters, rowsPerPage, currentPage, sortCriteria })
        }}
        defaultSortCriteria={defaultSortCriteria}
        columnDeps={[searchQuery,params?.companyId]}
        {...tableProps}
      />
    </>

  }

  const tableProps = {
    headerButtonsRight: addUser,
    headerButtonsLeft: exportCertficate,
    showSelectColumns: true,
    selectedColumns:[selectedColumns, setSelectedColumns],
    clearFilters: true,
    className: "white-header"
  }

  if (companyId) return table({ tableProps })

  const options=[{ id: null, name: "All Companies" }].concat(params.companies ?? [])
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <h3 className="mt-4 mb-4">{t("navbar.users")}</h3>
          <small className="ml-3">
            {exportCertficate}
          </small>
          <AlertCertificate />
        </div>
        {addUser}
      </div>
      <div className="card border-0 shadow">
        <div className="card-body">
          {table({
            tableProps: {
              headerButtonsLeft: (
                <div style={{ minWidth: "250px" }}>
                  <label className="w-100">{t("customers.company")}:</label>
                  <ReactSelectPagination
                  styles={customStyles}
                  value={options.find(company => company.id == params?.companyId )}
                  options={options}
                  onChange={handleCompanyChange}
                  getOptionLabel={(option)=>option.name}
                  getOptionValue={(option)=>option.id}
                  getFilteredResults={getFilteredResults}
                  pagination={companiesPagination}
                  setPagination={setCompaniesPagination}
                  isClearable={params?.companyId==null?false: true}
                  />
                </div>
              ),
              className: "white-header-page",
              showSelectColumns: true,
              selectedColumns:[selectedColumns, setSelectedColumns]
            }
          })}
        </div>
      </div>
    </>
  )
};

export default Users;
