import React, { useState } from 'react'
import Loader from '../Layout/Loader'
import DatePicker from "react-datepicker"
import mainAxios from '../../services/mainAxios'
import { useAppContext, usePersistedState } from '../../AppContext'
import useStateWithCallback from '../../services/useStateWithCallback'
import selectStyle from "../../design/selectStyle";
import { useTranslation, withTranslation } from "react-i18next";
import PrimeTable from '../PrimeTable/PrimeTable'
import DateRange from '../global/DateRange'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import * as xlsx from 'xlsx';
import { useExportData } from '../global/hooks/useExportData'
import ExportCsvPrime from '../global/ExportCsvPrime'
import { defaultTableRows } from '../../services/data'
import CellInput, { CellTextarea } from '../global/CellInput'



const moment = require("moment")

const InvoiceLogs = () => {
    const { selectedCompany: [selectedCompany,],user:[user] } = useAppContext()
    const [report,setReport]=useState({open:false,value:"1 minutes"})
    const [expandedRows, setExpandedRows] = React.useState([])
    const { t, i18n } = useTranslation('translations');
    const [invoicesForCSV, setInvoicesForCSV] = useState([]);

    const defaultFilters = {
        fromDate: moment().startOf('month')._d,
        toDate: moment().endOf('day')._d,
        params: {}
    };
    const [filters, setFilters] = React.useState(defaultFilters);

    const [persistedRowPerPage, setPersistedRowPerPage,] = usePersistedState(
        10,
        "rowsPerPage"
    );

    const defaultSortCriteria={
        field: "recordDate",
        direction: "DESC"
    }

    const [tableRows, setTableRows] = useState({
        ...defaultTableRows,
        rowsPerPage:persistedRowPerPage,
        sortCriteria:defaultSortCriteria
        
    });
    const [searchQuery, setSearchQuery] = React.useState("");

    const [state, setState] = useStateWithCallback({
        logs: null,
        auditActions: [],
        loading: false
    })
    const [loading, setLoading] = React.useState(false);

  

    React.useLayoutEffect(() => {
        mainAxios.post('apiEndpoint/search', {
            object: "GetAuditActions",
            value: null
        }).then(res => {
            if  (res?.data?.status === true) setState(state => ({  ...state, auditActions: res.data.content  }))
        })
    }, [])

    React.useEffect(() => {
        getFilteredResults({})
    }, [selectedCompany])

    const getFilteredResults = ({ 
        pageSize = persistedRowPerPage ?? defaultTableRows.rowsPerPage, 
        page = defaultTableRows.currentPage, 
        sortCriteria = [ defaultSortCriteria ],
        getLogsForCsv = false, 
        updatedFilters = filters, 
        fromDate = defaultFilters.fromDate, 
        toDate = defaultFilters.toDate
    } = {}) => {
        
        setLoading(true);
        if (invoicesForCSV.length > 0) setInvoicesForCSV([]);
        
        return mainAxios.post("apiEndpoint/search", {
            object: "GetLogs",
            value: null,
            fromDate,
            toDate,
            params: {
                ...updatedFilters.params
            },
            pagination: getLogsForCsv ? null : {
                pageSize,
                pageNumber: page,
            },
            sortCriteria
        }).then((res) => {
            if (res.data.status) {
                setLoading(false);
                
                if (getLogsForCsv) {
                    setInvoicesForCSV(res.data.content);
                } else {
                    setTableRows((tableRows) => ({
                        ...tableRows,
                        totalRows: res.data.totalSize,
                    }));
                    setState((state) => ({ ...state, logs: res.data.content }));
                }
                return true;
            }
            return false;
        }).catch(error => {
            setLoading(false);
            return false;
        });
    };
    

    const type = [
        { value: "error", name: "Error" },
        { value: "success", name: "Success" },
        { value: "process", name: "Process" },
    ]
    const entity = [
        { value: "invoice", name: t("navbar.invoice") },
        { value: "wtn", name: "WTN" },
        { value: "cashOperation", name: "Cash Operation" },
        { value: "companySettings", name: "Company Settings" },
        { value: "bu", name: "BU" },
        { value: "tcr", name: "TCR" },
        { value: "contacts", name: "Contacts" },
        { value: "items", name: t("navbar.products")  },
    ]
    const source = [
        { value: "api", name: "API" },
        { value: "agent", name: "Agent" },
        { value: "manual", name: "Manual" },
        { value: "schedulerResend ", name: "Scheduler Resend " },
        { value: "schedulerNotif", name: "Scheduler Notification " },
        { value: "schedulerGetInvoices ", name: "Scheduler Get Invoices " },
    ]
    const menu = [
        { value: "myPos ", name: "MyPOS " },
        { value: "myInvoice ", name: "MyInvoice" },
        { value: "myPurchase", name: "MyPurchase" },
        { value: "wtn ", name: "WTN" },
        { value: "search", name: "Search" },
        { value: "myBook  ", name: "MyBook" },
    ]

    const columns = [
        {
            expander: true,
            field:"expand",
            frozen: true,
            style: { minWidth: "8px", maxWidth: "8px" },
        },
        {
            header: t(['logs.action']),
            field: 'auditActions.displayName',
            // style: { minWidth: "140px", maxWidth: "150px" },
            default:true,
            filterType: "DROPDOWN",
            filterConfig: {
                options: state.auditActions ?? [],
                optionLabel: "displayName",
                optionValue: "displayName",
                splitKey: false,
                panelStyle:{
                    maxWidth:350
                }
            },
            format: (invoice) => invoice?.auditActions?.displayName,
            // body: (action) => action?.auditActions?.displayName,
            body: (action) => <CellTextarea width={140} value={action?.auditActions?.displayName ?? ""} onFocus={(event) => event.target.select()} />,
            filter: true,
        },
        {
            header: t(['logs.object']),
            field: 'auditObject',
            filter: true,
            format: (invoice) => invoice?.auditObject?.displayName,
            style: { minWidth: "165px", maxWidth: "165px" },
            body: (log) => log?.auditObject?.displayName,
        },
        {
            header: t(['subscription.type']),
            field: 'type',
            default:true,
            style: { minWidth: "150px", maxWidth: "150px" },
            filterType: "DROPDOWN",
            filterConfig: {
                options: type,
                optionLabel: "name",
                optionValue: "value",
            },
            filter: true,
        },
        {
            header: t(['common.source']),
            field: 'source',
            default:true,
            style: { minWidth: "150px", maxWidth: "150px" },
            filterType: "DROPDOWN",
            filterConfig: {
                options: source,
                optionLabel: "name",
                optionValue: "value",
            },
            filter: true,
        },
        {
            header: t(['home.date']),
            field: 'recordDate',
            default:true,
            formattedExportValue: true,
            preventExcelFormat: true,
            filterType: "DATE",
            filterConfig: {
                field: "invoiceDate"
            },
            body: log => log.recordDate ? moment(log.recordDate).format("DD/MM/YYYY HH:mm") : "",
            style: { minWidth: "190px", maxWidth: "190px" },
            filter: true,
            sortable: false
        },
        {
            header: t(['logs.user']),
            field: 'recordUser',
            default:true,
            filter:true,
            body: log => log.recordUser ? log.recordUser : "",
            style: { minWidth: "140px", maxWidth: "140px" },
        },
        {
            header: "NIVF/NIVFSH",
            field: 'fic',
            default:true,
            filter:true,
            body: (action) => <CellTextarea width={180} value={action?.fic?? ""} onFocus={(event) => event.target.select()} />,
            // style: { minWidth: "180px", maxWidth: "180px" },
        },
        {
            header: "NSLF/NSLFSH",
            field: 'iic',
            default:true,
            filter:true,
            body: (action) => <CellTextarea width={180} value={action?.iic?? ""} onFocus={(event) => event.target.select()} />,
            // style: { minWidth: "180px", maxWidth: "180px" },
        },
        {
            header: t("purchases.bussinesCode"),
            field: 'branchId',
            default:true,
            filter:true,
            body: log => log?.branchId?.businUnitCode ?? "" ,
            style: { minWidth: "150px", maxWidth: "150px" },
        },
        {
            header: "ERP ID",
            field: 'erpId',
            default:true,
            filter:true,
            body: log => log.erpId ? log.erpId : "",
            style: { minWidth: "150px", maxWidth: "150px" },
        },
        {
            header: t(['navbar.notifications']),
            field: 'notification',
            default: true,
            filter: true,
            filterType: "TRISTATECHECKBOX",
            dataType: "boolean",
            body: log => log.notification ? log.notification : "",
            style: { minWidth: "120px", maxWidth: "120px" },
        },
        {
            header: t(['logs.notificationStatus']),
            field: 'notifStatus',
            default: true,
            filter: true,
            filterType: "TRISTATECHECKBOX",
            dataType: "boolean",
            body: log => log.notifStatus ? log.notifStatus : "",
            style: { minWidth: "210px", maxWidth: "210px" },
        },
        {
            header: t(['company.operatorCode']),
            field: 'opcode',
            default:true,
            filter:true,
            body: log => log.opcode ? log.opcode : "",
            style: { minWidth: "150px", maxWidth: "150px" },
        },
        {
            header: "Menu",
            field: 'menu',
            filterType: "DROPDOWN",
            filterConfig: {
                options: menu,
                optionLabel: "name",
                optionValue: "value",
            },
            filter:true,
            body: log => log.menu ? log.menu : "",
            style: { minWidth: "150px", maxWidth: "150px" },
        },
        {
            header: t(['logs.entity']),
            field: 'entity',
            filterType: "DROPDOWN",
            filterConfig: {
                options: entity,
                optionLabel: "name",
                optionValue: "value",
            },
            filter:true,
            body: log => log.entity ? log.entity : "",
            style: { minWidth: "150px", maxWidth: "150px" },
        },
        {
            header: t(['customers.company']),
            field: 'company',
            filter:true,
            sortable: true,
            body: log => log.company ? log.company : "",
            style: { minWidth: "150px", maxWidth: "150px" },
        },
        {
            header: 'Info',
            field: 'logInfo',
            filter: true,
            // style: { minWidth: "180px", maxWidth: "180px" },
            body: (log) => <CellTextarea width={170} value={log?.logInfo ?? ""} onFocus={(event) => event.target.select()} />,

        }
    ];

    
    const [selectedColumns, setSelectedColumns] = usePersistedState(
        columns.map((col) => ({ field: col.field, show: !!col.default, ...(col?.persisted ? { persisted: true } : {}) })),
        "logsColumns"
    );

    const expandableColumns = [
        {
            header: t(['logs.action']),
            field: 'auditActions.displayName'
        },
        {
            header: t(['logs.object']),
            field: 'auditObject.displayName'
        },
        {
            header: t(['Info']),
            field: 'logInfo'
        }
    ]

    const rowExpansionTemplate = (e) => {
        return (
            <div className='prime-header-sticky' style={{ marginBottom: 20 }}>
                <PrimeTable
                    value={expandedRows}
                    columns={expandableColumns}
                    simpleTable={true}
                    showFilters={false}
                    paginator={false}
                    filterDisplay={false}
                    responsiveLayout="scroll"
                />
            </div>
        )
    }


    const reportInterval=[
        {
            label:"1 minute",
            value:"1 minutes"
        },
        {
            label:"5 minuta",
            value:"5 minutes"
        },
        {
            label:"10 minuta",
            value:"10 minutes"
        },
        {
            label:"1 ore",
            value:"1 hours"
        },
        {
            label:"3 ore",
            value:"3 hours"
        },
        {
            label:"12 ore",
            value:"12 hours"
        },
        {
            label:"24 ore",
            value:"24 hours"
        },
        {
            label:"48 ore",
            value:"48 hours"
        },
        {
            label:"72 ore",
            value:"72 hours"
        },
    ]

    const downloadReportRequest = () => {
        return mainAxios.post("/reports/v1", {
            value: report.value,
            nuis: user.companiesRights.find(
                (company) => company.id === parseInt(selectedCompany)
            )?.nuis,
            fromDate: filters.fromDate,
            toDate: filters.toDate
        })
        .then(res => {
            if (res.data.status && res?.data?.content?.length > 0) {
                return res.data.content;
            }
            return null;
        })
        .catch(err => {
            return null;
        });
    };
    
    const { exportData, loadingExport } = useExportData({ fetchFunc: downloadReportRequest });
    
    const handleExportAndClose = () => {
        getFilteredResults({ getLogsForCsv: true, fromDate: filters.fromDate, toDate: filters.toDate }).then(() => {
            exportData();
            setReport((state) => ({ ...state, open: false }));
        });
    };
    
    const downloadReport = (
        <>
        <div className='d-flex align-items-center'>
            <small className="ml-3">
                <div
                    onClick={() => { setReport((state) => ({ ...state, open: true })) }}
                    className="btn btn-light bg-white shadow-sm btn-custom btn-sm text-primary"
                >
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faDownload} className="mr-2" />
                        {t("logs.performanceReport")}
                    </div>
                </div>
            </small>
            <small className="ml-3">
            <ExportCsvPrime
                    data={invoicesForCSV}
                    columns={columns}
                    onClick={() => getFilteredResults({ getLogsForCsv: true, fromDate: filters.fromDate, toDate: filters.toDate })}
                    excludedColumns={["expand"]}
                />

            </small>
        </div>

            <Dialog
                header={t("logs.performanceReport")}
                visible={report?.open}
                onHide={() => { setReport((state) => ({ ...state, open: false, })) }}
                style={{ width: "20vw", height: "28vh", zIndex: 1000 }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}

            >

                {
                    report?.open && (
                        <>
                            <div className='d-flex justify-content-center align-items-center h-full'>

                                <div className='d-flex align-items-end gap-2' style={{ width: 300 }}>
                                    <div>
                                        <label>{t("logs.chooseIntervalInterval")}</label>
                                        <Dropdown value={report.value}
                                            className='w-full'
                                            onChange={(e) => setReport(state => ({ ...state, value: e.value }))}
                                            options={reportInterval} optionLabel="label"
                                            optionValue="value" />
                                    </div>
                                    <button className='btn btn-primary' disabled={loadingExport}  onClick={handleExportAndClose}>
                                        Shkarko
                                    </button>
                                </div>


                            </div>
                        </>
                    )
                }
            </Dialog>
        </>
    )
    return (
        state.logs ?
            <>
                <div className='d-flex align-items-center justify-content-between my-2'>
                    <div className='d-flex ailgn-items-center my-2'>
                        <div>
                            <h3 className="m-0">{t('navbar.logs')}</h3>
                        </div>
                        <div>
                        {downloadReport}
                            <small className="ml-3">
                                {/* <a
                        className="btn btn-light bg-white shadow-sm btn-custom btn-sm text-primary"
                    >
                        <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faDownload} className="mr-2" />
                            Shkarko Raport 2
                        </div>
                    </a> */}
                            </small>
                        </div>
                    </div>
                    <div>
                        <div className='mr-5'>
                            <DateRange
                                fromDate={filters.fromDate}
                                toDate={filters.toDate}
                                onChange={(dates) => {
                                    const [fromDate, toDate] = dates;
                                    if (fromDate && toDate) {
                                        getFilteredResults({ fromDate, toDate });
                                    }
                                    setFilters((filters) => ({
                                        ...filters,
                                        fromDate,
                                        toDate,
                                    }));
                                }}
                            />
                        </div>
                    </div>

                </div>
                <div className="card border-0 shadow">
                    <div className="card-body m-0 p-0">
                    <PrimeTable
                            loading={loading}
                            columns={columns}
                            value={[...state.logs]}
                            rowExpansionTemplate={rowExpansionTemplate}
                            globalFilterValue={[searchQuery, setSearchQuery]}
                            tableRows={[tableRows, setTableRows]}
                            clearFilters={true}
                            showSelectColumns
                            selectedColumns={[selectedColumns, setSelectedColumns]}
                            reorderableColumns={true}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            persistedRowPerPage={[persistedRowPerPage, setPersistedRowPerPage]}
                            filters={[filters, setFilters]}
                            defaultFilters={defaultFilters}
                            expandedRows={expandedRows}
                            defaultSortCriteria={defaultSortCriteria}
                            headerButtonsRight
                            onRemoteDataChange={(remoteData) => {
                                const updatedFilters = remoteData.filters ?? filters;
                                const updatedTableData = remoteData.tableRows ?? tableRows;
                                let { currentPage, rowsPerPage, sortCriteria } = updatedTableData;
                                sortCriteria = sortCriteria ? [sortCriteria] : [];
                                getFilteredResults({ 
                                    updatedFilters, 
                                    pageSize: rowsPerPage, 
                                    page: currentPage, 
                                    sortCriteria,
                                    fromDate: updatedFilters.fromDate,
                                    toDate: updatedFilters.toDate
                                });
                            }}
                        />


                    </div>
                </div>
            </>
            :
            <Loader />
    )
}

export default InvoiceLogs